import { FC, HTMLAttributes } from 'react'

import { deriveFontFamilyFromStyle } from '@syconium/magnolia/src/brunswick/containers/embroidery/utils/unorganized'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'

import { DetailLine, TextEmbroideryVisual } from './styles'
import { Template } from './Template'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string
  textColor?: string
  textFirstLine?: string
  textFont?: string
  textFontValue?: string
  textPlacement?: string
  textSecondLine?: string
}

export const TextSneakPeek: FC<Props> = ({
  backgroundColor,
  textColor,
  textFirstLine,
  textFont,
  textPlacement,
  textSecondLine,
  ...rest
}) => {
  const {
    embroidery: {
      summary: {
        textColorLabel,
        textFirstLineLabel,
        textFontLabel,
        textPlacementLabel,
        textSecondLineLabel,
      },
    },
  } = useFixturesContext()

  const textFontValue = textFont ? deriveFontFamilyFromStyle(textFont) : undefined

  if (!textPlacement) return null

  return (
    <Template.Container {...rest}>
      <Template.Header backgroundColor={backgroundColor}>
        {textFirstLine && (
          <TextEmbroideryVisual fontFamily={textFontValue} textColor={textColor?.toLowerCase()}>
            <span>{textFirstLine}</span>
            {textSecondLine && <span>{textSecondLine}</span>}
          </TextEmbroideryVisual>
        )}
      </Template.Header>

      <Template.Body>
        {textFirstLine && (
          <DetailLine general>
            {textFirstLineLabel}: {textFirstLine}
          </DetailLine>
        )}

        {textSecondLine && (
          <DetailLine general>
            {textSecondLineLabel}: {textSecondLine}
          </DetailLine>
        )}

        {textFont && (
          <DetailLine>
            {textFontLabel}: {textFont}
          </DetailLine>
        )}

        {textColor && (
          <DetailLine>
            {textColorLabel}: {textColor}
          </DetailLine>
        )}

        {textPlacement && (
          <DetailLine>
            {textPlacementLabel}: {textPlacement}
          </DetailLine>
        )}
      </Template.Body>
    </Template.Container>
  )
}
