import styled from 'styled-components'

import { Accordion, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const Body = styled.div<{
  inKit?: boolean
}>`
  padding-bottom: 20px;
`

export const Inner = styled.div<{
  inKit?: boolean
}>`
  margin-left: ${o => (o.inKit ? '16.5px' : 0)};

  ${fromMd} {
    margin-left: ${o => (o.inKit ? '24px' : 0)};
  }
`

export const Top = styled.div<{
  inKit?: boolean
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${o => o.theme.spacing(5, 0, 3, 0)};
`

export const Label = styled.span`
  ${TypeStyle.css.bodyDefault};
  font-weight: bold;
  color: ${o => o.theme.color.deprecated.obsidian};
  flex-grow: 1;
`

export const CostBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(1)};
  margin-top: ${o => o.theme.spacing(3)};
`

export const CostBreakdownRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CostBreakdownLabel = styled(TypeStyle.BodyTiny).attrs({
  color: 'tertiary',
  textTransform: 'uppercase',
})`
  font-weight: ${o => o.theme.typography.font.weight['600']};
  letter-spacing: 1px;
  line-height: 12px;
`

export const StyledAccordionHeader = styled(Accordion.Header)`
  align-items: center;
  text-align: left;
  padding: 0;
`

export const StyledAccordionBody = styled(Accordion.Body)`
  padding-bottom: 0;
`
