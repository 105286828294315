import * as React from 'react'

import { CartItem } from '../../../../../types/figs'
import { CartItemProperties } from '../../../../interfaces/remote-data/cart'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

const hasEnumerableGiftCardProperties = (item: CartItem): boolean => {
  return Boolean(
    item.productType === 'Gift Card' &&
      item.properties?.name &&
      item.properties?.email &&
      item.properties?.message
  )
}

export const RegularCartItem: React.FC<{
  cartCurrency: string
  cartIsUpdating: boolean
  cartItem: CartItem
  decrementQuantity(): void
  eventCategory: 'minicart' | 'cart'
  hasEmbroidery?: boolean
  incrementQuantity(): void
  isEmbroiderable?: boolean
  removeItem(): void
  setQuantity(newQuantity: number): void
  toggleMiniCartSlideOut?: () => void
  uniqueId: string
  /**
   * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
   * @deprecated do not use outside of temporary Product Bundle Discounts work.
   * @todo remove this after we remove this feature.
   */
  showDiscountAtCheckoutCopy: boolean
}> = passedProps => {
  let properties: CartItemProperties = passedProps.cartItem.properties
  if (hasEnumerableGiftCardProperties(passedProps.cartItem)) {
    properties = {
      Name: passedProps.cartItem?.properties?.name,
      Email: passedProps.cartItem?.properties?.email,
      Message: passedProps.cartItem?.properties?.message,
    }
  }
  const viewModel = useViewModel(properties)
  return <Template {...passedProps} {...viewModel} />
}
