import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const XSocial: React.FC<IProps> = ({ height = '20px', width = '20px', ...rest }) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 24 24' {...rest}>
      <path
        fill='currentColor'
        d='m14.037 10.1624 8.7805-10.1624h-2.0807l-7.6241 8.82384-6.08936-8.82384h-7.02334l9.20829 13.3432-9.20829 10.6568h2.08081l8.05129-9.3183 6.4308 9.3183h7.0233l-9.5497-13.8376zm-2.85 3.2984-.933-1.3287-7.42344-10.57248h3.19601l5.99083 8.53228.933 1.3287 7.7874 11.0907h-3.196l-6.3548-9.05z'
      />
    </BaseIcon>
  )
}
