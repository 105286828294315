import { useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import { useInitialSizeProfile } from './useInitialSizeProfile'

export type Recommendation = {
  fit?: string | null
  size?: string | null
  category?: string | null
}

export type Recommendations =
  | {
      men?: Recommendation[]
      women?: Recommendation[]
    }
  | undefined
const containsRecommendations = (input: Recommendations, gender?: 'men' | 'women') => {
  if (!input) return false
  const genderedRecommendations = gender && input[gender]
  return Boolean(genderedRecommendations && genderedRecommendations.length > 0)
}
const useFitQuizUserState = (initialProductGroupGender: 'men' | 'women' | undefined) => {
  const { recommendations: initialRecommendations, loading } = useInitialSizeProfile()
  const [resubmitQuizFlowActive, setResubmitQuizFlowActive] = useState(false)
  const [step, setStep] = useState<'intro' | 'loading' | 'recommendation' | 'error'>('intro')
  const [recommendations, setRecommendations] = useState<Recommendations>(initialRecommendations)
  const [selectedGender, setSelectedGender] = useState<'men' | 'women' | undefined>(undefined)

  const useInitialRecommendations = !containsRecommendations(recommendations, selectedGender)
  const computedRecommendations = useMemo(() => {
    return !useInitialRecommendations ? recommendations : initialRecommendations
  }, [recommendations, initialRecommendations, useInitialRecommendations])

  const computedStep = useMemo(() => {
    if (resubmitQuizFlowActive) {
      return 'intro'
    }
    if (step === 'error') return 'error'
    if (loading || step === 'loading') return 'loading'
    if (
      !containsRecommendations(recommendations, selectedGender ?? initialProductGroupGender) &&
      !containsRecommendations(initialRecommendations, selectedGender ?? initialProductGroupGender)
    )
      return 'intro'
    return 'recommendation'
  }, [
    recommendations,
    loading,
    initialRecommendations,
    resubmitQuizFlowActive,
    step,
    initialProductGroupGender,
    selectedGender,
  ])

  return {
    step: computedStep,
    setStep,
    recommendations: computedRecommendations,
    setRecommendations,
    selectedGender,
    setSelectedGender,
    setResubmitQuizFlowActive,
  }
}

export const FitQuizUserStateContainer = createContainer(useFitQuizUserState)
