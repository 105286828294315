import { gql } from '../../../__generated__/graphql/catalog'

export const SUBMIT_CUSTOMER_SIZE_PROFILE = gql(`
  mutation submitCustomerSizeProfile ($id: ID, $input: SubmitCustomerSizeProfileInput!) {
    submitCustomerSizeProfile(id: $id, input: $input) {
      id
      gender
      age
      height
      weight
      recommendations {
        category
        size
        fit
      }
    }
  }
  `)
