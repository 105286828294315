import { Size } from '@syconium/little-miss-figgy'
import { FETCH_PRODUCT_REVIEWS } from '@syconium/magnolia/src/lib/graphql/queries'
import { AvailableProducts, deriveMapFromList } from '@syconium/magnolia/src/lib/utils'

import { GET_TILE_PRODUCT_GROUP_FOR_COLLECTION } from '../../lib/graphql/queries/GET_TILE_PRODUCT_GROUP_FOR_COLLECTION'
import { ProductTag } from '../../lib/reviews/types'
import { AnalyticsContext, Cart, CartItem, PersistedCartItem } from '../../types/figs'
import { FavoritesItem } from '../../types/graphql'
import { ICartClient } from '../interfaces/remote-data/cart'

import { sizeChart } from './size-chart'

import type {
  Collection,
  Embroidery,
  EmbroideryFont,
  EmbroideryIconAsset,
  EmbroideryPlacementDetails,
  EmbroideryProductKind,
  EmbroideryVariant,
  FitModel,
  IconBadge,
  Product,
  ProductAsset,
  ProductColor,
  ProductFilter,
  ProductGroup,
  ProductGroupWithProductAvailability,
  ProductPriceRangeByColors,
  ProductReviewSet,
  ProductWithAvailability,
  SearchAutocompleteProduct,
  SearchResults,
  Variant,
} from '../../types/graphql'

export const sizes: Size[] = [
  { shorthand: 'XXS', soldOut: true },
  { shorthand: 'XS', soldOut: false },
  { shorthand: 'S', soldOut: false },
  { shorthand: 'M', soldOut: false },
  { shorthand: 'L', soldOut: false },
  { shorthand: 'XL', soldOut: true },
  { shorthand: '2XL', soldOut: false },
]

export const sizeShortHands: string[] = sizes.map(size => size.shorthand)

export const productImages: ProductAsset[] = [
  {
    color: 'Black',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'regular',
    id: 'id-1',
    index: 1,
    kind: 'black',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Casma-Top_black-1.jpg?v=1596835293',
    type: 'ProductImage',
  },
  {
    color: 'Black',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'regular',
    id: 'id-4',
    index: 2,
    kind: 'black',
    name: 'Black',
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Casma-Top_black-2.jpg?v=1596835293',
    type: 'ProductImage',
  },
  {
    color: 'Ceil Blue',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'tall',
    id: 'id-2',
    index: 2,
    kind: 'flatlay-chest',
    name: 'Ceil Blue',
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/figs-embroidery-womens_Casma_CB_40b580b6-bf45-440c-8e77-459f9fbedfa1.jpg?v=1596836591',
    type: 'ProductImage',
  },
  {
    color: 'Graphite',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-3',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
]

export const productImagesExtended: ProductAsset[] = [
  ...productImages,
  {
    color: 'Teal',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-5',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
  {
    color: 'Charcoal',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-6',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
  {
    color: 'Charcoal',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-7',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
  {
    color: 'Lavender Dew',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-45',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
  {
    color: 'Bonsai',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-8',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
  {
    color: 'Burgundy',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-9',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
  {
    color: 'White',
    designFile: null,
    embroideryIconGroup: null,
    embroideryOffsets: null,
    fit: 'petite',
    id: 'id-10',
    index: 3,
    kind: 'ghost',
    name: null,
    source:
      'https://cdn.shopify.com/s/files/1/0139/8942/products/w_FW1100_graphite_transparent.png?v=1596837122',
    type: 'ProductImage',
  },
]

export const fitModel: FitModel = {
  description: 'Elizabeth is 5\'2" wearing XS petite',
  height: '5\'2"',
  images: [
    'https://creative.wearfigs.com/m/770250ea17ab707b/webimage-Womens-Catarina-Top_blackS-1',
    'https://creative.wearfigs.com/m/7b2040ad34554aa0/webimage-Womens-Catarina-Top_blackS-2',
    'https://creative.wearfigs.com/m/5ac96a57cb54dcfd/webimage-Womens-Catarina-Top_blackS-3',
    'https://creative.wearfigs.com/m/6869445bec0b6f00/webimage-Womens-Catarina-Top_blackS-4',
    'https://creative.wearfigs.com/m/52fcd01d50f1d2b5/webimage-Womens-Catarina-Top_blackS-5',
  ],
  name: 'Elizabeth',
  pantsSize: 'XS',
  topSize: 'S',
}

export const iconBadge: IconBadge = {
  caption: 'pockets',
  icon: { url: 'https://cdn.shopify.com/s/files/1/0139/8942/files/icon-phone.png' },
  iconText: '7',
}

export const product: Product = {
  availableForSale: true,
  availableSizes: sizeShortHands,
  badgeList: ['baller', 'dank'],
  careInstructions: 'Care instruction directions',
  category: 'Scrub Top',
  collectionBadgeColor: '#500',
  collectionBadgeText: 'Tasty',
  collectionLinks: { nodes: [{ text: 'hey', href: '/some-page' }] },
  color: 'Black',
  colorInfo: {
    availability: 'classics',
    handle: 'black',
    hexCodes: ['#282828'],
    name: 'Black',
    rawName: 'Black',
  },
  finalSale: false,
  fit: 'regular',
  fitModels: [fitModel],
  fitModelsDescriptions: ["Model 1 is 5'2 and wearing an S / Regular Length"],
  defaultVariant: {
    availableForSale: true,
    color: 'Ceil Blue',
    shopifyId: '5h0p1fy1D',
    fit: 'petite',
    rawFit: 'petite',
    size: 'small',
    product: { id: 'asdf' },
    priceDetails: {
      discountPrice: {
        amount: 123,
      },
      price: {
        amount: 123,
        currency: 'USD',
        base: {
          amount: 123,
          currency: 'USD',
        },
      },
    },
  } as Variant,
  description: 'Three-Pocket Scrub Top',
  detailImages: [
    'https://d2o3top45uowdm.cloudfront.net/media/final/29cfe9c7-7f12-4f46-bd0f-4d1ffafce91f/webimage-E9025E29-1E9E-4FC0-9E713795322CE8C9.png',
    'https://d2o3top45uowdm.cloudfront.net/media/final/1bf2168e-63b6-4636-b5ae-1d356f5f9082/webimage-8AB4E72F-5C24-4110-9BAB563A4B21C0DE.png',
  ],
  embroidery: null,
  handle: 'some-product-handle',
  iconBadge,
  iconBadgePlacement: 'top left',
  iconList: [
    {
      id: '9',
      label: 'Lauren',
      image:
        'https://cdn.shopify.com/s/files/1/0139/8942/files/Artboard_5_copy_10.svg?1397963845350479501',
      title: 'MODEL IS: XS / 5"8"',
    },
    {
      id: '10',
      label: '3 pockets',
      image:
        'https://cdn.shopify.com/s/files/1/0139/8942/files/Artboard_5_copy_10.svg?1397963845350479501',
      title: '3 pockets',
    },
  ],
  id: 'casma-attribute-id',
  images: {
    nodes: productImages.slice(0, 2),
  },
  noindex: true,
  priceRange: {
    min: 3400,
    max: 3450,
  },
  productGroup: {
    category: 'Scrub Top',
    colors: [
      {
        availability: 'core',
        handle: 'black',
        hexCodes: ['#282828'],
        name: 'Black',
      },
      {
        availability: 'limited edition',
        handle: 'ceil-blue',
        hexCodes: ['#5f7fc3'],
        name: 'Ceil Blue',
      },
      {
        availability: 'core',
        handle: 'graphite',
        hexCodes: ['#8d8d97'],
        name: 'Graphite',
      },
    ],
    defaultVariant: {
      fit: 'petite',
      availableForSale: true,
    },
    handle: 'cts-handle',
    fits: ['petite', 'regular'],
    variants: [
      {
        color: 'Ceil Blue',
        shopifyId: '5h0p1fy1D',
        fit: 'petite',
        size: 'small',
        product: { id: 'asdf' },
      } as Variant,
    ],
    products: [],
    rawFits: ['petite', 'regular'],
    sizes: sizeShortHands,
    sizeChart,
    title: "Women's Three Pocket Scrub Top",
  } as unknown as ProductGroup,
  rawCategory: 'Scrub Top',
  rawFit: 'regular',
  rawMaterials: ['fabric'],
  seoDescription: 'SEO FRIENDLY casma scrub top',
  seoTitle: "SEO FRIENDLY Women's Casma Top",
  shopifyId: 'gid://shopify/Product/69946505130057',
  soldOut: false,
  title: "Women's Casma Three-Pocket Scrub Top",
  updatedAt: '2020-08-16T21:42:38Z',
  videos: {
    nodes: [
      {
        kind: 'catwalk',
        embroideryIconGroup: null,
        embroideryOffsets: null,
        designFile: null,
        color: 'Black',
        fit: '',
        id: 'some-video-id',
        index: 1,
        name: 'Black Catwalk',
        source: 'video.mp4',
        type: 'ProductVideo',
      },
    ],
  },
  whyWeLove: "It's grrrrreeeat!",
  waitlistable: true,
}

export const productTestFitsA: Product = {
  ...product,
  color: 'Blue',
  colorInfo: {
    availability: '',
    handle: 'blue',
    hexCodes: ['#0000ff'],
    name: 'Blue',
    rawName: 'Blue',
  },
}

export const productTestFitsB: Product = {
  ...product,
  color: 'Blue',
  colorInfo: {
    availability: '',
    handle: 'blue',
    hexCodes: ['#0000ff'],
    name: 'Blue',
    rawName: 'Blue',
  },
  fit: 'petite',
  rawFit: 'petite',
}

export const productColors: ProductColor[] = [
  {
    accentColor: 'Black',
    availability: 'core',
    handle: 'black',
    hexCodes: ['#282828'],
    name: 'Black',
    rawName: 'Black',
    swatchUrl: '',
  },
  {
    accentColor: 'Ceil Blue',
    availability: 'limited edition',
    handle: 'ceil-blue',
    hexCodes: ['#5f7fc3'],
    name: 'Ceil Blue',
    rawName: 'Ceil Blue',
    swatchUrl: '',
  },
  {
    accentColor: 'Graphite',
    availability: 'core',
    handle: 'graphite',
    hexCodes: ['#8d8d97'],
    name: 'Graphite',
    rawName: 'Graphite',
    swatchUrl: '',
  },
  {
    accentColor: 'Navy',
    availability: 'core',
    handle: 'navy',
    hexCodes: ['#403F6F'],
    name: 'Navy',
    rawName: 'Navy',
    swatchUrl: '',
  },
  {
    accentColor: 'Deep Royal Blue',
    availability: 'limited edition',
    handle: 'deep-royal-blue',
    hexCodes: ['#0F4CC1'],
    name: 'Deep Royal Blue',
    rawName: 'Deep Royal Blue',
    swatchUrl: '',
  },
  {
    accentColor: 'Royal Blue',
    availability: 'core',
    handle: 'royal-blue',
    hexCodes: ['#095BDB'],
    name: 'Royal Blue',
    rawName: 'Royal Blue',
    swatchUrl: '',
  },
  {
    accentColor: 'Burgundy',
    availability: 'core',
    handle: 'burgundy',
    hexCodes: ['#692A2D'],
    name: 'Burgundy',
    rawName: 'Burgundy',
    swatchUrl: '',
  },
  {
    accentColor: 'Alps Blue',
    availability: 'limited edition',
    handle: 'alps-blue',
    hexCodes: ['#0281AE'],
    name: 'Alps Blue',
    rawName: 'Alps Blue',
    swatchUrl: '',
  },
  {
    accentColor: 'Caribbean Blue',
    availability: 'limited edition',
    handle: 'caribbean-blue',
    hexCodes: ['#18455A'],
    name: 'Caribbean Blue',
    rawName: 'Caribbean Blue',
    swatchUrl: '',
  },
  {
    accentColor: 'Charcoal',
    availability: 'limited edition',
    handle: 'charcoal',
    hexCodes: ['#383640'],
    name: 'Charcoal',
    rawName: 'Charcoal',
    swatchUrl: '',
  },
  {
    accentColor: 'Denim',
    availability: 'limited edition',
    handle: 'denim',
    hexCodes: ['#4960B2'],
    name: 'Denim',
    rawName: 'Denim',
    swatchUrl: '',
  },
  {
    accentColor: 'Ice Grey',
    availability: 'limited edition',
    handle: 'ice-grey',
    hexCodes: ['#CACCC7'],
    name: 'Ice Grey',
    rawName: 'Ice Grey',
    swatchUrl: '',
  },
  {
    accentColor: 'Ultra Violet',
    availability: 'limited edition',
    handle: 'ultra-violet',
    hexCodes: ['#7B48A7'],
    name: 'Ultra Violet',
    rawName: 'Ultra Violet',
    swatchUrl: '',
  },
]

export const availableProducts = productColors.reduce((acc, curr) => {
  acc[curr.name] = {
    regular: ['XXS', 'XS', 'S', 'M', 'L'],
  }
  return acc
}, {} as AvailableProducts)

export const colorsByName = deriveMapFromList(productColors, c => c.name)

export const productColor = productColors[0]

export const productPriceRangeByColors: ProductPriceRangeByColors = {
  'black': {
    min: 3400,
    max: 3450,
  },
  'sky-blue': {
    min: 3800,
    max: 4000,
  },
}

export const productGroupForVariant: ProductGroup = {
  careInstructions: product.careInstructions,
  category: 'cool',
  colors: productColors,
  portalColorGroups: [],
  colorGroups: [
    {
      colors: productColors,
      label: 'core',
    },
  ],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultVariant: null as any,
  description: product.description,
  embroiderable: true,
  embroideryImages: [],
  embroideryPlacementDetails: {
    black: {
      left: {
        x: '75%',
        y: '30%',
      },
      right: {
        x: '25%',
        y: '30%',
      },
    },
  },
  fitModelsDescriptions: product.fitModelsDescriptions,
  fits: ['tall', 'petite', 'regular'],
  genderCategory: 'womens',
  handle: 'womens-casma-top',
  iconBadge,
  iconBadgePlacement: 'top right',
  iconList: product.iconList,
  id: 'casma-id',
  images: productImages,
  products: [product],
  rawFits: ['tall', 'petite', 'regular'],
  rawMaterials: product.rawMaterials,
  seoDescription: product.seoDescription,
  seoTitle: product.seoTitle,
  sizeChart: product.productGroup.sizeChart,
  sizes: sizeShortHands,
  style: 'Casma™',
  title: product.title,
  variants: [],
  whyWeLove: product.whyWeLove,
  fitQuiz: {
    categories: ['ScrubCap'],
    introText: null,
    recommendedFitText: null,
    handle: 'handle',
    id: '12345',
    exitLink: null,
  },
}

export const productGroupForVariantTestFits: ProductGroup = {
  ...productGroupForVariant,
  products: [product, productTestFitsA, productTestFitsB],
}

export const variant: Variant = {
  availableForSale: true,
  category: 'tops',
  color: 'Black',
  currencyType: 'USD',
  externalParentId: 'gid://shopify/Product/69746505130050',
  description: 'blah blah blah',
  discountPrice: null,
  discountPriceShortFmt: '$38.00',
  discountPriceExplicitFmt: '$38.00 USD',
  genderCategory: 'womens',
  handle: 'some-product-handle',
  id: 'product-id',
  image: productImages[0]!,
  images: productImages,
  name: 'Black Casma Top',
  fit: 'regular',
  price: 48,
  priceShortFmt: '$48.00',
  priceExplicitFmt: '$48.00 USD',
  priceDetails: {
    discountPrice: {
      amount: 123,
    },
    price: {
      amount: 123,
      currency: 'USD',
      base: {
        amount: 123,
        currency: 'USD',
      },
    },
  },
  product,
  productGroup: productGroupForVariant,
  rawColor: 'Black',
  rawFit: 'regular',
  shopifyId: 'gid://shopify/ProductVariant/16546505130050',
  size: 'S',
  soldOut: false,
  sku: 'T21004TBND:S',
  style: 'casma-three-pocket',
  upc: '194389107570',
  videos: [
    {
      kind: 'catwalk',
      embroideryIconGroup: null,
      embroideryOffsets: null,
      designFile: null,
      color: 'Black',
      fit: '',
      id: 'some-video-id',
      index: 1,
      name: 'Black Catwalk',
      source: 'video.mp4',
      type: 'ProductVideo',
    },
  ],
}

export const variantDiscounted = {
  ...variant,
  priceDetails: {
    discountPrice: {
      amount: 29,
    },
    price: {
      amount: 48,
      currency: 'USD',
      base: {
        amount: 48,
        currency: 'USD',
      },
    },
  },
}

export const productNavyDiscounted = {
  ...product,
  color: 'Navy',
  variants: [variantDiscounted],
}

export const productGroup: ProductGroup = {
  ...productGroupForVariant,
  featuredProduct: product,
  defaultVariant: variant,
  variants: [variant],
}

export const productGroupTestFits: ProductGroup = {
  ...productGroupForVariantTestFits,
  defaultVariant: variant,
  variants: [variant],
}

export const productGroupWithProductAvailability: ProductGroupWithProductAvailability = {
  ...productGroup,
  productsForAvailability: [
    {
      availableSizes: ['S', 'M', 'L'],
      colorInfo: {
        rawName: 'Black',
      },
      rawFit: 'petite',
      __typename: 'Product',
      id: '',
      fit: null,
      swPromoEligible: false,
      priceRange: undefined,
      defaultVariant: {
        __typename: undefined,
        price: undefined,
        discountPrice: undefined,
      },
    },
  ],
}

export const productWithAvailability: ProductWithAvailability = {
  ...product,
  productGroup: productGroupWithProductAvailability,
  shopTheLook: { nodes: [] },
}

export const productWithVariants: Product = {
  ...product,
  variants: [variant],
}

export const productReviews: ProductReviewSet = {
  averageScore: 3.14159,
  averageFit: 4.27,
  totalReviews: 875,
  tag: ProductTag.Tops,
  starDistribution: {
    oneStarRatings: 0,
    twoStarRatings: 0,
    threeStarRatings: 800,
    fourStarRatings: 75,
    fiveStarRatings: 0,
  },
  reviews: [
    {
      content: 'The Figs scrubs is perfect!',
      score: 5,
      reviewer: 'F. Bar',
      title: 'F1GS',
      createdAt: new Date('2024-03-15T21:42:34+00:00'),
      customFields: [
        {
          key: 48465,
          title: 'Size Purchased',
          formId: null,
          value: 'M',
        },
        {
          key: 48922,
          title: 'Fit',
          formId: null,
          value: '2',
        },
        {
          key: 48892,
          title: 'Height',
          formId: null,
          value: "5'7 - 5'9",
        },
        {
          key: 48468,
          title: 'Weight',
          formId: null,
          value: '155',
        },
        {
          key: 48469,
          title: 'Occupation',
          formId: null,
          value: 'Tech',
        },
      ],
      imageUrls: null,
    },
    {
      content:
        "I've been on the watch for the burgundy colors for a whole year! As soon as I spoke to a FIGS representative about bringing the burgundy colors back, they posted them! I love all the styles and the materials it's made of.",
      score: 4,
      reviewer: 'Sako H.',
      title: 'I LOVE IT!',
      customFields: [
        {
          key: 48465,
          title: 'Size Purchased',
          formId: null,
          value: 'M',
        },
        {
          key: 48922,
          title: 'Fit',
          formId: null,
          value: '3',
        },
        {
          key: 48892,
          title: 'Height',
          formId: null,
          value: "5'7 - 5'9",
        },
        {
          key: 48468,
          title: 'Weight',
          formId: null,
          value: '165',
        },
        {
          key: 48469,
          title: 'Occupation',
          formId: null,
          value: 'Nursing',
        },
      ],
      imageUrls: null,
      createdAt: new Date('2024-03-15T21:42:34+00:00'),
    },
  ],
  pagination: {
    numPages: 420,
    numPerPage: 5,
    pageNum: 7,
  },
}

export const collectionFilters: ProductFilter[] = [
  {
    key: 'color',
    crawlable: false,
    displayName: 'Color',
    displayType: 'basicColor',
    filterValues: [
      { state: 'ENABLED', value: 'Black' },
      { state: 'ENABLED', value: 'Navy' },
      { state: 'ENABLED', value: 'Graphite' },
      { state: 'ENABLED', value: 'Ceil Blue' },
      { state: 'ENABLED', value: 'Royal Blue' },
      { state: 'ENABLED', value: 'Alps Blue' },
      { state: 'ENABLED', value: 'Burgundy' },
      { state: 'ENABLED', value: 'Clay' },
      { state: 'ENABLED', value: 'Denim' },
      { state: 'ENABLED', value: 'Moss' },
      { state: 'ENABLED', value: 'Ultra Violet' },
      { state: 'ENABLED', value: 'Neon Pink' },
    ],
  },
  {
    key: 'product type',
    crawlable: false,
    displayName: 'Product Type',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'Mask' },
      { state: 'ENABLED', value: 'Scrub Jacket' },
      { state: 'ENABLED', value: 'Scrub Pants' },
      { state: 'ENABLED', value: 'Scrub Top' },
    ],
  },
  {
    key: 'length',
    crawlable: false,
    displayName: 'Length',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'regular' },
      { state: 'ENABLED', value: 'petite' },
      { state: 'ENABLED', value: 'tall' },
    ],
  },
  {
    key: 'size',
    crawlable: false,
    displayName: 'Size',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'XXS' },
      { state: 'ENABLED', value: 'XS' },
      { state: 'ENABLED', value: 'S' },
      { state: 'ENABLED', value: 'M' },
      { state: 'ENABLED', value: 'L' },
      { state: 'ENABLED', value: 'XL' },
      { state: 'ENABLED', value: '2XL' },
      { state: 'ENABLED', value: 'S/M' },
      { state: 'ENABLED', value: 'M/L' },
    ],
  },
  {
    key: 'feature',
    crawlable: false,
    displayName: 'Feature',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'eleven-pockets' },
      { state: 'ENABLED', value: 'five-pockest' },
      { state: 'ENABLED', value: 'five-pockets' },
      { state: 'ENABLED', value: 'four-pockets' },
      { state: 'ENABLED', value: 'jogger' },
      { state: 'ENABLED', value: 'one-pocket' },
      { state: 'ENABLED', value: 'petite' },
      { state: 'ENABLED', value: 'seven-pockets' },
      { state: 'ENABLED', value: 'straight' },
      { state: 'ENABLED', value: 'tall' },
      { state: 'ENABLED', value: 'three-pockets' },
      { state: 'ENABLED', value: 'two-pockets' },
    ],
  },
  {
    key: 'fabric',
    crawlable: false,
    displayName: 'Fabric',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'FIONx' },
      { state: 'ENABLED', value: 'FREEx' },
    ],
  },
  {
    key: 'neckline',
    crawlable: false,
    displayName: 'Neckline',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'V-Neck' },
      { state: 'ENABLED', value: 'Mandarin' },
      { state: 'ENABLED', value: 'Polo' },
      { state: 'ENABLED', value: 'Henley' },
    ],
  },
  {
    key: 'style',
    crawlable: false,
    displayName: 'Style',
    displayType: 'basicCheckbox',
    filterValues: [
      { state: 'ENABLED', value: 'Catarina' },
      { state: 'ENABLED', value: 'Casma' },
      { state: 'ENABLED', value: 'Kitale' },
      { state: 'ENABLED', value: 'Nala' },
      { state: 'ENABLED', value: 'Rafaela' },
      { state: 'ENABLED', value: 'Gidya' },
      { state: 'ENABLED', value: 'Marsh' },
    ],
  },
]

export const selectedFilters: Record<string, string[]> = deriveMapFromList(
  collectionFilters,
  o => o.key,
  o => o.filterValues?.slice(1).map(o => o.value) ?? []
)

export const collection: Collection = {
  availableForSale: true,
  filters: collectionFilters,
  handle: 'core-collection',
  id: 'collection-id',
  name: 'Core Collection',
  productGroups: [productGroup],
  productHandles: ['mens-moree-scrub-top', 'mens-tansen-scrub-pants'],
}

export const history = {
  replace: () => undefined,
}

export const location = {
  search: '?color=black',
}

const scrubTopGuid = '50M3-GU1D'
const teamsEmbroideryGroupId = 'teams-embroidery-group-id'

export const embroiderableTopItem: CartItem = {
  image: {
    alt: 'Scrub Top One',
    url: 'https://sandbox.wearfigs.com/scrub-top-one.png',
  },
  key: 'key-2',
  fullPrice: 3800,
  effectivePrice: 3800,
  handle: 'embroidable-top-item-handle',
  productGroupHandle: 'embroiderable-top-item',
  productId: 'gid://shopify/Product/52046505130059',
  productType: 'Scrub Top One',
  properties: {
    _guid: scrubTopGuid,
  },
  quantity: 1,
  sku: 's-top-1',
  title: 'One Scrub Top',
  description: '',
  variantId: '789012',
  finalSale: false,
  swPromoEligible: true,
}

export const embroideryItem: CartItem = {
  image: {
    alt: 'Embroidery Options',
    url: 'embroidery-options.png',
  },
  key: 'key-4',
  fullPrice: 1400,
  effectivePrice: 1400,
  handle: 'embroidable-top-item-handle',
  productGroupHandle: 'embroidery-item',
  productId: 'gid://shopify/Product/86546505130059',
  productType: 'Embroidery',
  properties: {
    _embroidery_version: '1.5',
    _guid: scrubTopGuid,
  },
  quantity: 1,
  sku: 'e-opts',
  title: 'Text Embroidey',
  description: '',
  variantId: '707510',
  finalSale: false,
  swPromoEligible: true,
}

export const teamsEmbroiderableProduct: CartItem = {
  image: {
    alt: 'Catarina',
    url: 'catarina-scrup-top.png',
  },
  key: 'key-34',
  fullPrice: 3500,
  effectivePrice: 3500,
  handle: 'embroidable-top-item-handle',
  productGroupHandle: 'teams-embroiderable-product',
  productId: 'gid://shopify/Product/86542505190059',
  productType: 'Scrub Top',
  properties: {
    _groupid: teamsEmbroideryGroupId,
  },
  quantity: 1,
  sku: 'teams-catarina',
  title: 'Teams Embroiderable Catarina',
  description: '',
  variantId: '283978',
  finalSale: false,
  swPromoEligible: true,
}

export const teamsEmbroideryItem: CartItem = {
  image: {
    alt: 'CHLA Embroidery Options',
    url: 'chla-embroidery-options.png',
  },
  key: 'key-84',
  fullPrice: 1600,
  effectivePrice: 1600,
  handle: 'embroidable-top-item-handle',
  productGroupHandle: 'teams-embroidery-item',
  productId: 'gid://shopify/Product/67542505190059',
  productType: 'Embroidery',
  properties: {
    _groupId: teamsEmbroideryGroupId,
  },
  quantity: 1,
  sku: 'teams-e-opts',
  title: 'Teams Embroidery',
  description: '',
  variantId: '765443',
  finalSale: false,
  swPromoEligible: true,
}

export const portalPersistedCartItem: PersistedCartItem = {
  key: 'portalItemKey',
  productType: 'portal',
  properties: {
    _guid: 'portalGuid',
  },
  quantity: 1,
  sku: 'PORTAL_SKU',
  variantId: 'PORTAL_VARIANT_ID',
  swPromoEligible: true,
  isPortalColor: true,
}

export const portalEmbroideryPersistedCartItem: PersistedCartItem = {
  key: 'embroideryItemKey',
  productType: 'embroidery',
  properties: {
    _guid: 'portalGuid',
    _embroidery_version: '1.5',
  },
  quantity: 1,
  sku: 'EMBROIDERY_SKU',
  variantId: 'EMBROIDERY_VARIANT_ID',
}

export class MockCartClient implements ICartClient {
  public addItems(): Promise<CartItem[]> {
    return Promise.resolve([])
  }

  public fetchCart(): Promise<Cart | null> {
    return Promise.resolve(null)
  }

  public removeItems(): Promise<CartItem[]> {
    return Promise.resolve([])
  }

  public setItemsQuantities(): Promise<CartItem[]> {
    return Promise.resolve([])
  }

  public clearCheckoutIfCompleted(): boolean | Promise<boolean> {
    return false
  }

  public createCheckoutWithCartItems(_items: CartItem[]): Promise<string> {
    return Promise.resolve('https://us.shop.staging.wearfigs.com/checkout')
  }

  public get locallyStoredCheckoutId(): string | null {
    return null
  }

  public associateCustomer(_shopifyToken: string): Promise<void> {
    return Promise.resolve()
  }
}

export const productFilter: ProductFilter = {
  crawlable: false,
  displayName: 'Color',
  displayType: 'basicColor',
  filterValues: [
    { state: 'ENABLED', value: 'Black' },
    { state: 'ENABLED', value: 'Navy' },
  ],
  key: 'options.color',
}

export const productFilterSingleValue: ProductFilter = {
  crawlable: false,
  displayName: 'Category',
  displayType: 'basicCheckbox',
  filterValues: [{ state: 'ENABLED', value: 'Scrub Tops' }],
  key: 'options.category',
}

export const searchPageResults: { search: SearchResults } = {
  search: {
    suggestions: [{ query: 'scrub tops' }],
    items: {
      colors: [product.colorInfo!],
      nodes: [
        {
          product: product,
        },
      ],
      filters: [productFilter, productFilterSingleValue],
      numItems: 1,
      pageInfo: {
        endCursor: '',
        hasNextPage: false,
      },
    },
  },
}

export const searchAutocompleteProduct: SearchAutocompleteProduct = {
  category: 'Scrub Pants',
  defaultVariant: {
    ...variant,
    priceDetails: {
      discountPrice: {
        amount: 39,
      },
      price: {
        amount: 48,
        currency: 'USD',
        base: {
          amount: 48,
          currency: 'USD',
        },
      },
    },
  },
  handle: 'womens-zamora-jogger-scrub-pants-mauve',
  productGroup: {
    handle: 'womens-zamora-scrub-pants',
    rawFits: ['regular', 'tall', 'short'],
  },
  colorInfo: {
    rawName: 'Mauve',
  },
  id: '1000',
  title: "Women's Zamora™ Jogger Scrub Pants",
  shopifyId: '1000',
  url: '/products/womens-zamora-jogger-scrub-pants',
}

export const embroideryPlacementDetails: EmbroideryPlacementDetails[] = [
  {
    flatlayKind: 'flatlay-chest',
    name: 'Left Chest',
    offsetKey: 'left-chest',
  },
  {
    flatlayKind: 'flatlay-chest',
    name: 'Right Chest',
    offsetKey: 'right-chest',
  },
]

export const iconEmbroideryImages: EmbroideryIconAsset[] = [
  {
    ...productImages[0]!,
    designFile: 'ambulance-icon',
    kind: 'icon',
    name: 'Ambulance',
    source:
      'https://purepng.com/public/uploads/large/purepng.com-ambulanceambulanceinjured-peoplefor-an-illness-or-injuryhospital-medicalambulances-1701527405425oanfl.png',
    embroideryOffsets: {
      designFile: 'ambulance',
    },
  },
  {
    ...productImages[0]!,
    designFile: 'pill-icon',
    kind: 'icon',
    name: 'Pill',
    source: 'https://pngimg.com/uploads/pills/pills_PNG16534.png',
    embroideryOffsets: {
      designFile: 'pill',
    },
  },
]

export const iconEmbroidery: EmbroideryVariant = {
  ...variant,
  images: [
    {
      ...productImages[0]!,
      designFile: 'ambulance-icon',
      kind: 'icon',
      name: 'Ambulance',
      source:
        'https://purepng.com/public/uploads/large/purepng.com-ambulanceambulanceinjured-peoplefor-an-illness-or-injuryhospital-medicalambulances-1701527405425oanfl.png',
      embroideryOffsets: {
        designFile: 'ambulance',
        scale: 1.2,
      },
    },
    {
      ...productImages[0]!,
      designFile: 'pill-icon',
      kind: 'icon',
      name: 'Pill',
      source: 'https://pngimg.com/uploads/pills/pills_PNG16534.png',
      embroideryOffsets: {
        designFile: 'pill',
      },
    },
  ],
  price: 1200,
  sku: 'icon-embroidery',
}

export const iconAndTextEmbroideryVariant: EmbroideryVariant = {
  ...variant,
  images: [
    {
      ...productImages[0]!,
      designFile: 'ambulance-icon',
      kind: 'icon',
      name: 'Ambulance',
      source:
        'https://purepng.com/public/uploads/large/purepng.com-ambulanceambulanceinjured-peoplefor-an-illness-or-injuryhospital-medicalambulances-1701527405425oanfl.png',
      embroideryOffsets: {
        designFile: 'ambulance',
      },
    },
    {
      ...productImages[0]!,
      designFile: 'pill-icon',
      kind: 'icon',
      name: 'Pill',
      source: 'https://pngimg.com/uploads/pills/pills_PNG16534.png',
      embroideryOffsets: {},
    },
  ],
  price: 2200,
  sku: 'icon-and-text-embroidery',
}

export const embroiderySetupVariant: EmbroideryVariant = {
  ...variant,
  category: 'Embroidery',
  images: [],
  priceDetails: {
    price: {
      amount: 6000,
      currency: 'USD',
      base: {
        amount: 6000,
        currency: 'USD',
      },
    },
  },
  price: 6000,
  shopifyId: 'embroidery-setup-variant',
  sku: 'embroidery-setup-variant',
}

export const embroideryColors: string[] = ['White', 'Black']

export const embroideryFonts: EmbroideryFont[] = [
  {
    name: 'Block',
    scale: 1,
  },
  {
    name: 'Script',
    scale: 1.38,
  },
]

export const embroideryVariant: EmbroideryVariant = {
  ...variant,
  images: variant.images.map(o => ({ ...o, embroideryOffsets: {} })),
}

export const textEmbroideryOption: Embroidery<EmbroideryVariant> = {
  iconPlacements: [],
  initialsColors: null,
  initialsFonts: null,
  initialsMaxChars: 2,
  kind: 'TEXT_ONLY',
  variants: [embroideryVariant],
  textColors: embroideryColors,
  textFonts: embroideryFonts,
  textMaxChars: 20,
  textPlacements: [
    {
      flatlayKind: 'flatlay-chest',
      name: 'Left Chest',
      offsetKey: 'left-chest',
    },
    {
      flatlayKind: 'flatlay-chest',
      name: 'Right Chest',
      offsetKey: 'right-chest',
    },
  ],
  logoPlacements: [],
  textLines: 2,
}

export const logoEmbroideryOption: Embroidery<EmbroideryVariant> = {
  iconPlacements: [],
  initialsColors: null,
  initialsFonts: null,
  initialsMaxChars: 2,
  kind: 'LOGO_ONLY',
  variants: [embroideryVariant],
  textColors: [],
  textFonts: [],
  textMaxChars: null,
  textPlacements: [],
  logoPlacements: [
    {
      flatlayKind: 'flatlay-chest',
      name: 'Left Chest',
      offsetKey: 'left-chest',
    },
    {
      flatlayKind: 'flatlay-chest',
      name: 'Right Chest',
      offsetKey: 'right-chest',
    },
  ],
  textLines: 2,
}

export const iconTextEmbroideryOption: Embroidery<EmbroideryVariant> = {
  ...textEmbroideryOption,
  kind: 'ICON_TEXT' as EmbroideryProductKind,
  variants: [iconAndTextEmbroideryVariant],
}

export const iconEmbroideryOption: Embroidery<EmbroideryVariant> = {
  variants: [iconEmbroidery],
  iconPlacements: [
    {
      flatlayKind: 'flatlay-chest',
      name: 'Left Chest',
      offsetKey: 'left-chest',
    },
    {
      flatlayKind: 'flatlay-chest',
      name: 'Right Chest',
      offsetKey: 'right-chest',
    },
    {
      flatlayKind: 'flatlay-left-sleeve',
      name: 'Left Sleeve',
      offsetKey: 'left-sleeve',
    },
  ],
  kind: 'ICON_ONLY',
  initialsColors: embroideryColors,
  initialsFonts: embroideryFonts,
  initialsMaxChars: 2,
  textFonts: null,
  textColors: null,
  textMaxChars: 25,
  textPlacements: [],
  logoPlacements: [],
  textLines: 2,
}

export const embroideryOptions: Embroidery[] = [textEmbroideryOption, iconEmbroideryOption]

export const flatlayChestImage: ProductAsset = {
  ...productImages[0]!,
  kind: 'flatlay-chest',
  source:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/figs-embroidery-womens_Casma_Black_06e4ab8b-f2fd-4421-90d7-638785fc204a.jpg',
  embroideryOffsets: {
    'left-chest': {
      x: '75%',
      y: '40%',
    },
    'right-chest': {
      x: '25%',
      y: '40%',
    },
  },
}

export const flatlayDefaultImage: ProductAsset = {
  ...productImages[0]!,
  kind: 'flatlay-default',
  source:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/figs-embroidery-womens_Casma_Black_06e4ab8b-f2fd-4421-90d7-638785fc204a.jpg',
  embroideryOffsets: {
    'left-chest': {
      x: '75%',
      y: '40%',
    },
    'right-chest': {
      x: '25%',
      y: '40%',
    },
  },
}

export const flatlayLeftSleeveImage: ProductAsset = {
  ...productImages[0]!,
  color: 'Black',
  kind: 'flatlay-left-sleeve',
  source: 'https://cdn.shopify.com/s/files/1/0139/8942/products/Womens-Casma-Top_black-3.jpg',
  embroideryOffsets: {
    'left-sleeve': {
      x: '47%',
      y: '67%',
    },
  },
}

export const productWithEmbroideryOptions: Product = {
  ...product,
  embroidery: [textEmbroideryOption, iconEmbroideryOption, iconTextEmbroideryOption],
}

export const productGroupWithEmbroideryOptions: ProductGroup = {
  ...productGroup,
  products: [productWithEmbroideryOptions],
  images: [flatlayDefaultImage, flatlayChestImage, flatlayLeftSleeveImage],
  variants: [],
}

export const analyticsContext: AnalyticsContext = {
  category: 'Category',
  list: 'list',
}

export const apolloMocks = [
  {
    request: {
      query: FETCH_PRODUCT_REVIEWS,
      variables: { handle: 'womens-casma-top' },
    },
    result: {
      data: {
        productReviews: {
          averageScore: 1,
          numReviews: 1,
          reviews: [],
          pageInfo: {
            numPages: 1,
            numPerPage: 2,
            pageNum: 1,
          },
        },
      },
    },
  },
  {
    request: {
      query: GET_TILE_PRODUCT_GROUP_FOR_COLLECTION,
      variables: { handle: 'womens-casma-top' },
    },
    error: new Error('mocking this call because somehow this test is dependent on it.'),
  },
]

export const persistedCartItems: Record<string, PersistedCartItem> = {
  abc: {
    key: 'abc',
    productType: 'Scrub Top',
    properties: {},
    quantity: 1,
    sku: variant.sku,
    variantId: 'GID-abc',
    swPromoEligible: true,
  },
  xyz: {
    key: 'xyz',
    productType: 'Scrub Top',
    properties: {
      _someThing: 'value',
    },
    quantity: 2,
    sku: 'SKU-xyz',
    variantId: 'GID-xyz',
    swPromoEligible: true,
  },
}

export const persistedCartItemsNewTab: Record<string, PersistedCartItem> = {
  abc: {
    key: 'abc',
    productType: 'Scrub Top',
    properties: {},
    quantity: 1,
    sku: variant.sku,
    variantId: 'GID-abc',
    swPromoEligible: true,
  },
  xyz: {
    key: 'xyz',
    productType: 'Scrub Top',
    properties: {
      _someThing: 'value',
    },
    quantity: 2,
    sku: 'SKU-xyz',
    variantId: 'GID-xyz',
    swPromoEligible: true,
  },
  def: {
    key: 'def',
    productType: 'Scrub Top',
    properties: {
      _someThing: 'value',
    },
    quantity: 2,
    sku: 'SKU-def',
    variantId: 'GID-def',
    swPromoEligible: true,
  },
}

const kitCartItems: Record<string, CartItem> = {
  xyz: {
    color: 'blue',
    image: {
      alt: 'alt',
      url: 'url',
    },
    fullPrice: 100,
    effectivePrice: 99,
    productId: '123',
    handle: 'product-handle',
    productGroupHandle: 'handle',
    title: 'title',
    description: 'this is a description',
    finalSale: false,
    key: 'xyz',
    productType: 'Scrub Top',
    properties: {
      _kitId: 'value',
    },
    quantity: 2,
    sku: 'SKU-xyz',
    variantId: 'GID-xyz',
    swPromoEligible: true,
  },
  abc: {
    color: 'blue',
    image: {
      alt: 'alt',
      url: 'url',
    },
    fullPrice: 100,
    effectivePrice: 99,
    productId: '123',
    handle: 'product-handle',
    productGroupHandle: 'handle',
    title: 'title',
    description: 'this is a description',
    finalSale: false,
    key: 'xyz',
    productType: 'Scrub Top',
    properties: {
      _kitId: 'value',
    },
    quantity: 2,
    sku: 'SKU-abc',
    variantId: 'GID-abc',
    swPromoEligible: true,
  },
}

export const mockCartWithKits: Cart = {
  currencyCode: 'CAD',
  itemCount: 2,
  items: kitCartItems,
  totalPrice: 100,
  id: '012345',
}

export const mockVariantsBySku = {
  'SKU-xyz': {
    ...variant,
    sku: 'SKU-xyz',
  },
  'SKU-abc': {
    ...variant,
    sku: 'SKU-abc',
  },
}

export const filterableCategories: string[] = ['Scrub Top', 'Scrub Bottom']
export const filterableFits: string[] = ['regular', 'petite', 'tall']
export const filterableSizes: string[] = ['S', 'M', 'L']

export const favoritesItem: FavoritesItem = {
  productHandle: 'womens-casma-three-pocket-scrub-top-black',
  preferences: {
    size: 'M',
    fit: 'regular',
    color: 'Black',
  },
  product: {
    ...productWithAvailability,
    tiers: ['all'],
  },
}
