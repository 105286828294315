import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const LOGIN_CUSTOMER = gql(`
  mutation loginCustomer($email: String!, $password: String!) {
    loginCustomer(email: $email, password: $password) {
      customerAccessToken
      refreshToken
      token
    }
  }
`)
