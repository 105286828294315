import { CSSProperties, useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { useBrowserStoredState } from '@syconium/little-miss-figgy'
import { useNavVisibility } from '@syconium/magnolia/src/lib/hooks/useNavVisibility'

import {
  promoBarHeightClosedPixels,
  promoBarHeightDesktopPixels as promoBarHeightDesktopPixelsConst,
  promoBarHeightMobilePixels as promoBarHeightMobilePixelsConst,
} from '../../constants'

const useNavContainer = (): {
  isHeaderPushedUp: boolean
  promoBarHeightDesktop: CSSProperties['height']
  promoBarHeightDesktopPixels: number
  promoBarHeightMobile: CSSProperties['height']
  promoBarHeightMobilePixels: number
  promoBarIsOpen: boolean
  setIsHeaderPushedUp: (x: boolean) => void
  setPromoBarIsOpen: (x: boolean) => void
} => {
  // Manage the visibility of the nav bar
  const { isVisible, forceVisibleState } = useNavVisibility()
  const setIsHeaderPushedUp = useCallback(
    (isPushedUp: boolean) => forceVisibleState(!isPushedUp),
    [forceVisibleState]
  )

  const {
    state: { promoBarIsOpen },
    setState: setPromoBarSessionState,
  } = useBrowserStoredState<{ promoBarIsOpen: boolean }>({
    initialState: { promoBarIsOpen: true },
    storage: globalThis.sessionStorage,
    storageKey: 'PromoBar.isOpen',
  })
  const setPromoBarIsOpen = useCallback(
    (x: boolean) => setPromoBarSessionState({ promoBarIsOpen: x }),
    [setPromoBarSessionState]
  )

  const promoBarHeightDesktopPixels = useMemo(() => {
    return promoBarIsOpen ? promoBarHeightDesktopPixelsConst : promoBarHeightClosedPixels
  }, [promoBarIsOpen])

  const promoBarHeightMobilePixels = useMemo(() => {
    return promoBarIsOpen ? promoBarHeightMobilePixelsConst : promoBarHeightClosedPixels
  }, [promoBarIsOpen])

  const promoBarHeightDesktop = useMemo(() => {
    return `${promoBarHeightDesktopPixels}px`
  }, [promoBarHeightDesktopPixels])

  const promoBarHeightMobile = useMemo(() => {
    return `${promoBarHeightMobilePixels}px`
  }, [promoBarHeightMobilePixels])

  return {
    isHeaderPushedUp: !isVisible,
    promoBarHeightDesktop,
    promoBarHeightDesktopPixels,
    promoBarHeightMobile,
    promoBarHeightMobilePixels,
    promoBarIsOpen,
    setIsHeaderPushedUp,
    setPromoBarIsOpen,
  }
}

export const NavContainer = createContainer(useNavContainer)
