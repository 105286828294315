import { PortalConfig } from '../containers/PortalConfigContainer/types'

import { collectionViewHeroSection } from './contentful-collection-view'

export const embroidery: NonNullable<PortalConfig['embroidery']> = {
  addText: 'Do you want to add embroidery?',
  eligibleProductCategories: ['Scrub Top', 'Scrub Jacket'],
  image: 'https://cdn.shopify.com/s/files/1/0298/0967/2276/products/EMB-icon.png?v=1609982382',
  logoName: 'Laserway Logo',
  noText: 'No',
  yesText: 'Yes',
  mandatoryTextEmbroideryCategories: ['Scrub Top', 'Scrub Pants'],
  mandatoryLogoEmbroideryCategories: ['Scrub Top', 'Scrub Pants'],
  hiddenTextEmbroideryFields: [],
  defaultTextEmbroideryValues: {
    textPlacement: null,
    font: null,
    textColor: null,
  },
  embroideryDiscountPercentage: 10,
}

export const portal: PortalConfig = {
  discountPercentage: 10,
  pdpCtaText: 'Shop Now',
  quickBuyText: '',
  embroidery,
  collectionHandle: 'laseraway-collection-2',
  collectionHeroSectionCG: collectionViewHeroSection,
  promoBarPages: [],
  name: 'Portal',
}
