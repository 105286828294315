'use client'

import { ForwardedRef, forwardRef } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

const RenderOnceInViewportVisibilityTargetChild = styled.div`
  height: 1px;
  width: 1px;
  pointer-events: none;
  position: absolute;
  top: 1px;
  left: 1px;
`

const RenderOnceInViewportVisibilityTargetWrapper = styled.div`
  height: 1px;
  left: 0;
  position: relative;
  top: 0;
  visibility: hidden;
  width: 1px;
  line-height: 0px;
  margin-right: -1px;
  margin-top: -1px;
`

export const RenderOnceInViewportVisibilityTarget = forwardRef(
  ({ className }: { className?: string }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <RenderOnceInViewportVisibilityTargetWrapper>
        <RenderOnceInViewportVisibilityTargetChild className={className} ref={ref} />
      </RenderOnceInViewportVisibilityTargetWrapper>
    )
  }
)

type RenderOnceInViewportProps = {
  forceRender?: boolean
  offset?: Readonly<{
    bottom?: number
    left?: number
    right?: number
    top?: number
  }>
  partialVisibility?: boolean
  VisibilityTarget?: (props: { ref: React.ForwardedRef<HTMLElement> }) => React.ReactNode
  children?: React.ReactNode
}

export const RenderOnceInViewport = ({
  children,
  forceRender = false,
  offset,
  partialVisibility = false,
  VisibilityTarget,
}: RenderOnceInViewportProps) => {
  const offsetString = [
    offset?.top || 0,
    offset?.right || 0,
    offset?.bottom || 0,
    offset?.left || 0,
  ]
    .map(value => `${value}px`)
    .join(' ')

  const { ref, inView } = useInView({
    threshold: partialVisibility ? 0 : 1,
    rootMargin: offsetString,
    triggerOnce: true,
    fallbackInView: true,
  })

  const render = forceRender || inView

  return (
    <>
      {render ? children : null}
      {VisibilityTarget ? (
        <VisibilityTarget ref={ref} />
      ) : (
        <RenderOnceInViewportVisibilityTarget ref={ref} />
      )}
    </>
  )
}
