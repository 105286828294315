import styled from 'styled-components'

import { TypeStyle } from '@syconium/little-miss-figgy'
import { ColorAttribute } from '@syconium/magnolia/src/components/attribute-selectors'

export const Container = styled.div``

export const PortalLink = styled.a`
  ${TypeStyle.css.headlineMicro}
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  text-decoration: underline;
  display: inline-block;
  margin-bottom: ${o => o.theme.spacing(2)};
  &:hover {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`

export const StyledColorAttribute = styled(ColorAttribute)`
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }
`
