import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const CREATE_CUSTOMER = gql(`
  mutation createCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      customerAccessToken
      refreshToken
      token
    }
  }
`)
