import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'

import { FitQuizType } from '../../../components/pages/fit-quiz/FitQuiz'

import type { Open, State } from './types'
import type { SizeChart } from '../../../types/graphql'

function useSizeChart(initialState: State | undefined = { modalState: 'Unopened' }) {
  const [state, setState] = useState<State>(initialState)
  const shouldDisplaySizeChartModal = state.modalState === 'Open'
  const router = useRouter()
  const openSizeChartModal = useCallback(
    (sizeChart?: SizeChart, fitQuiz?: FitQuizType, initialActiveTab?: number): void => {
      setState({
        sizeChart: sizeChart,
        fitQuiz: fitQuiz,
        modalState: 'Open',
        initialActiveTab: initialActiveTab ?? 0,
      })
    },
    []
  )

  const closeSizeChartModal = useCallback((): void => {
    setState(current => ({
      ...(current as Open),
      modalState: 'Closed',
    }))
  }, [])

  useEffect(() => {
    if (state.modalState !== 'Open') return

    router.events.on('routeChangeStart', closeSizeChartModal)
    return () => {
      router.events.off('routeChangeStart', closeSizeChartModal)
    }
  }, [closeSizeChartModal, router.events, state.modalState])

  return {
    closeSizeChartModal,
    openSizeChartModal,
    shouldDisplaySizeChartModal,
    state,
  }
}

export const SizeChartModalService = createContainer(useSizeChart)
