import uniq from 'lodash-es/uniq'

import { KnownFilterKeys } from '../../constants'

import { getQueryParamArray } from '.'

import type { CollectionItemFilterInput } from '../../types/graphql'

export function extractSelectedCollectionFilters({
  collectionFiltersQueryParam,
  legacyCategoryQueryParam,
  legacyColorQueryParam,
  legacyFitQueryParam,
}: {
  collectionFiltersQueryParam: string | string[] | null | undefined
  legacyCategoryQueryParam: string | string[] | null | undefined
  legacyColorQueryParam: string | string[] | null | undefined
  legacyFitQueryParam: string | string[] | null | undefined
}): Record<string, string[]> {
  const selectedFilters: Record<string, string[]> = (() => {
    if (!collectionFiltersQueryParam) return {}
    if (Array.isArray(collectionFiltersQueryParam)) return {}
    try {
      return JSON.parse(collectionFiltersQueryParam)
    } catch {
      return {}
    }
  })()

  /**
   * We want to continue supporting legacy collection filter query params
   * because we continue to specify link hrefs with URLs like ?color=Navy and
   * ?fit=petite in places like the site nav fixtures and SEO content sections.
   * At least some of these are being specified in Contentful.
   *
   * Here we merge these one-off legacy collection filter query params into the
   * selected filter values derived from the new singular ?filters query param
   * values
   */
  const mergedFilters: Record<string, string[]> = {
    ...selectedFilters,
  }

  const selectedLegacyCategoryFilters = getQueryParamArray(legacyCategoryQueryParam)
  const selectedLegacyColorFilters = getQueryParamArray(legacyColorQueryParam)
  const selectedLegacyFitFilters = getQueryParamArray(legacyFitQueryParam)

  if (selectedLegacyCategoryFilters.length > 0) {
    mergedFilters[KnownFilterKeys.ProductType] = uniq([
      ...(selectedFilters[KnownFilterKeys.ProductType] ?? []),
      ...selectedLegacyCategoryFilters,
    ])
  }
  if (selectedLegacyColorFilters.length > 0) {
    mergedFilters[KnownFilterKeys.Color] = uniq([
      ...(selectedFilters[KnownFilterKeys.Color] ?? []),
      ...selectedLegacyColorFilters,
    ])
  }
  if (selectedLegacyFitFilters.length > 0) {
    mergedFilters[KnownFilterKeys.Length] = uniq([
      ...(selectedFilters[KnownFilterKeys.Length] ?? []),
      ...selectedLegacyFitFilters,
    ])
  }

  return mergedFilters
}

export function deriveCollectionItemFilterInput(args: {
  collectionFiltersQueryParam: string | string[] | null | undefined
  legacyCategoryQueryParam: string | string[] | null | undefined
  legacyColorQueryParam: string | string[] | null | undefined
  legacyFitQueryParam: string | string[] | null | undefined
}): CollectionItemFilterInput[] {
  const selectedFilters = extractSelectedCollectionFilters(args)

  // Sorted by both key and values to improve probability of hitting cache
  return Object.entries(selectedFilters)
    .map(([key, values]) => ({
      key,
      values: values.sort((a, b) => (a < b ? -1 : 1)),
    }))
    .filter(o => o.values.length > 0)
    .sort((a, b) => (a.key < b.key ? -1 : 1))
}
