import React, { Dispatch, RefObject, SetStateAction, useEffect, useState } from 'react'

import { SizeChartFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { useFixturesContext } from '../../../../containers/fixtures'

import { Label, Nav, Radio, Span, WrapperButton } from './styles'

export const SizeChartModalNav: React.FC<{
  setSelectedTableIndex: Dispatch<SetStateAction<number>>
  tables: SizeChartFragmentFragment['tables']
  fitQuizTabPresent?: boolean
  initialActiveTab?: number
}> = ({ tables, setSelectedTableIndex, fitQuizTabPresent = false, initialActiveTab = 0 }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialActiveTab)
  const [focusedTabIndex, setFocusedTabIndex] = useState(initialActiveTab)
  const radioName = 'tableNav'
  const {
    magnolia: {
      fitQuiz: { findMyFit },
    },
  } = useFixturesContext()
  const onChange = (index: number) => {
    setSelectedTabIndex(index)
    setSelectedTableIndex(index)
  }

  const tabElementRefs: RefObject<HTMLButtonElement>[] = tables
    ? tables.map(() => React.createRef())
    : []
  if (fitQuizTabPresent) {
    tabElementRefs.push(React.createRef())
  }

  const currentElement = tabElementRefs[focusedTabIndex]
  const tabsLength = tabElementRefs.length
  const findMyFitIndex = tables ? tables.length : 0
  useEffect(() => {
    currentElement?.current?.focus()
  }, [selectedTabIndex, currentElement])

  const tabFocusHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowLeft') {
      setFocusedTabIndex(prev => {
        if (prev > 0) {
          return prev - 1
        } else return prev
      })
    } else if (e.key === 'ArrowRight') {
      setFocusedTabIndex(prev => {
        if (prev < tabsLength) {
          return prev + 1
        } else {
          return prev
        }
      })
    } else if (e.key === 'Enter') {
      setSelectedTabIndex(focusedTabIndex)
      setSelectedTableIndex(focusedTabIndex)
    }
  }

  return (
    <Nav role='tablist' aria-multiselectable='true'>
      {tables?.map((table, i) => {
        const tabIsActive = selectedTabIndex === i

        return (
          <WrapperButton
            key={`${i}-${table.tabLabel}`}
            role='tab'
            aria-selected={focusedTabIndex === i}
            aria-controls='sizeChartTabPanel'
            id='sizeChartTab'
            tabIndex={focusedTabIndex === i ? 0 : -1}
            ref={tabElementRefs[i]}
            onKeyDown={tabFocusHandler}
          >
            <Label>
              <Radio
                onChange={() => {
                  onChange(i)
                }}
                checked={tabIsActive}
                name={radioName}
                value={table.tabLabel || i}
              />
              <Span>{table.tabLabel}</Span>
            </Label>
          </WrapperButton>
        )
      })}
      {fitQuizTabPresent && (
        <WrapperButton
          role='tab'
          aria-selected={focusedTabIndex === findMyFitIndex}
          aria-controls='sizeChartTabPanel'
          id='sizeChartTab'
          tabIndex={focusedTabIndex === findMyFitIndex ? 0 : -1}
          ref={tabElementRefs[findMyFitIndex]}
          onKeyDown={tabFocusHandler}
        >
          <Label key={`findMyFit`}>
            <Radio
              onChange={() => {
                onChange(tabsLength - 1)
              }}
              checked={selectedTabIndex === findMyFitIndex}
              name={radioName}
              value={findMyFit.toUpperCase()}
            />
            <Span>{findMyFit.toUpperCase()}</Span>
          </Label>
        </WrapperButton>
      )}
    </Nav>
  )
}
