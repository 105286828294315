import React, { useState } from 'react'

import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { SizeChartFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { FitQuiz, FitQuizType } from '../../../../../components/pages/fit-quiz/FitQuiz'
import { useFixturesContext } from '../../../../containers/fixtures'
import { SizeChartTable } from '../../SizeChartTable'
import { SizeChartModalNav } from '../SizeChartModalNav'

import { CloseButton, Header, HeaderTextSmall, NavWrap, TableWrap } from './styles'

export const SizeChartModalTable: React.FC<{
  onClose?: () => void
  showHeader?: boolean
  sizeChart?: SizeChartFragmentFragment
  fitQuiz?: FitQuizType
  initialActiveTab?: number
}> = ({ onClose, showHeader = true, sizeChart, fitQuiz, initialActiveTab = 0 }) => {
  const tabsLength = (sizeChart?.tables?.length ?? 0) + Number(!!fitQuiz)
  //allow for reverse indexing with query param
  const calculatedInitialActiveTab =
    initialActiveTab >= 0 ? initialActiveTab : tabsLength + initialActiveTab
  const [selectedTableIndex, setSelectedTableIndex] = useState(calculatedInitialActiveTab)
  const selectedTable = sizeChart?.tables?.[selectedTableIndex]
  const fitQuizIndex = sizeChart?.tables ? sizeChart.tables.length : 0
  const {
    magnolia: {
      fitQuiz: { findMyFit },
    },
    sizesSection: {
      sizeChart: { modalHeadline },
    },
  } = useFixturesContext()
  return (
    <>
      {showHeader && (
        <Header backgroundColor='white' hasBottomBorder={true}>
          <HeaderTextSmall asTag='h2'>{sizeChart ? modalHeadline : findMyFit}</HeaderTextSmall>
          <CloseButton
            onClick={onClose}
            {...trackEvent({ category: 'product details', action: 'close size table modal' })}
          />
        </Header>
      )}
      {sizeChart && sizeChart?.tables?.length && sizeChart?.tables?.length > 1 ? (
        <NavWrap>
          <SizeChartModalNav
            {...{ setSelectedTableIndex }}
            initialActiveTab={calculatedInitialActiveTab}
            fitQuizTabPresent={Boolean(fitQuiz)}
            tables={sizeChart.tables}
          />
        </NavWrap>
      ) : null}
      {selectedTable ? (
        <TableWrap>
          <SizeChartTable {...selectedTable} />
        </TableWrap>
      ) : selectedTableIndex === fitQuizIndex && fitQuiz ? (
        <FitQuiz {...fitQuiz} />
      ) : null}
    </>
  )
}
