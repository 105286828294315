import styled, { css } from 'styled-components'

import {
  ResponsiveImage as LMFResponsiveImage,
  TypeStyle,
  fromMd,
  untilMd,
} from '@syconium/little-miss-figgy'

export const TitleBar = styled(TypeStyle.HeadlineExtraSmall)<{
  float?: string
}>`
  align-items: center;
  display: flex;
  float: ${o => o.float || 'left'};
  margin: 10px 0;
`

export const ToggleWrap = styled.div`
  margin-inline-start: auto;
`

export const Subtitle = styled(TypeStyle.BodyNano)``

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`

const tableFirstChild = css<{ showShadow?: boolean }>`
  &:first-child {
    border-inline-end: 1px solid ${o => o.theme.color.deprecated.stoneLight};
    break-word: break-all;
    font-size: 13px;
    inset-inline-start: 0;
    overflow-wrap: break-word;
    position: sticky;
    white-space: normal;

    &::after {
      background: linear-gradient(to right, rgba(17, 17, 17, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
      content: '';
      height: 100%;
      opacity: ${o => (o.showShadow ? 1 : 0)};
      position: absolute;
      inset-inline-end: -24px;
      top: 0;
      width: 24px;
    }

    .rtl &::after {
      background: linear-gradient(to left, rgba(17, 17, 17, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
    }

    ${untilMd} {
      max-width: 80px;
    }
  }
`

export const TableHeader = styled(TypeStyle.HeadlineMicro)<{
  showShadow?: boolean
}>`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  font-size: 13px;
  justify-content: center;
  min-height: 50px;
  min-width: 80px;
  padding: 0 20px;
  text-align: start;
  vertical-align: middle;

  ${tableFirstChild}
`
export const TableHead = styled.thead`
  height: 50px;
`

export const TableRow = styled.tr`
  height: 50px;
`

export const TableCell = styled.td<{
  showShadow?: boolean
}>`
  background: white;
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  font-size: 12px;
  font-weight: 400;
  min-width: 80px;
  padding: 0 20px;
  vertical-align: middle;

  ${tableFirstChild}

  &:first-child {
    ${TypeStyle.css.headlineMicro}
    font-size: 13px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`

export const SliderWrap = styled.div<{
  showShadow: boolean
}>`
  line-height: 0;
  margin: 10px 0;
  position: relative;

  ${o =>
    o.showShadow &&
    css`
      &::after {
        background: linear-gradient(
          to left,
          rgba(17, 17, 17, 0.12) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        bottom: 0;
        content: '';
        position: absolute;
        inset-inline-end: 0;
        top: 0;
        width: 24px;
      }

      .rtl &::after {
        background: linear-gradient(
          to right,
          rgba(17, 17, 17, 0.12) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `}
`

export const Slider = styled.div`
  border: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  border-bottom: none;
  overflow: auto;
  white-space: nowrap;
`

export const MeasureWrapper = styled(TitleBar)`
  align-items: center;
  background-color: ${o => o.theme.color.deprecated.stoneLighter};
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
`

export const MeasureSection = styled.div`
  flex-grow: 1;
  max-height: 345px;
  overflow: hidden;
`

export const MeasureTipsList = styled.ul`
  font-size: 14px;
  margin: auto;
  width: 90%;

  ${fromMd} {
    width: 70%;
  }
`
export const MeasureTipsListItem = styled.li``

export const MeasureTipKind = styled(TypeStyle.Eyebrow)`
  color: ${o => o.theme.color.deprecated.obsidianLight};
`

export const MeasureTipItem = styled(TypeStyle.BodyDefault)`
  padding-bottom: 10px;
`

export const ResponsiveImage = styled(LMFResponsiveImage)`
  height: 100%;
  margin: auto;
  max-height: 345px;
  max-width: 345px;
  width: auto;
`

export const SizeTipText = styled(TypeStyle.BodyNano)`
  padding-left: 5px;
`

export const SizeTipKind = styled(TypeStyle.BodyNano)`
  font-weight: 700;
`

export const SizeTipWrap = styled.div`
  display: flex;

  ${untilMd} {
    flex-direction: column;
  }
`

export const SizeTipTextWrap = styled.div`
  display: flex;
  padding-right: 10px;
`
