import styled from 'styled-components'

import { ResponsiveImage, TypeStyle, timingDefault } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  align-items: flex-start;
  display: flex;
`

export const Visual = styled.div<{
  backgroundColor?: string
}>`
  align-items: center;
  background: ${o => o.backgroundColor};
  box-sizing: border-box;
  display: flex;
  height: 104px;
  justify-content: center;
  max-width: 100px;
  min-width: 100px;
  overflow: hidden;
  transition: background-color ${timingDefault} ease;
  border-radius: ${o => o.theme.spacing(1)};
  border: 1px solid ${o => o.theme.color.deprecated.stone};
`

export const TextEmbroideryVisual = styled.div<{
  fontFamily?: string
  textColor?: string
}>`
  color: ${o => o.textColor};
  font-family: ${o => o.fontFamily};
  font-size: 10px;
  line-height: normal;
  text-align: center;

  span {
    display: block;
  }
`

export const IconEmbroideryVisual = styled.div`
  align-items: center;
  display: flex;
`

export const Icon = styled(ResponsiveImage)`
  height: 36px;
  width: 100%;
  object-fit: fill;
`

export const Initials = styled.span<{
  initialsColor?: string
}>`
  color: ${o => o.initialsColor};
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-inline-start: 4px;
`

export const Details = styled.div`
  flex-grow: 1;
  padding-inline-start: 20px;
`

export const DetailLine = styled.span<{
  general?: boolean
}>`
  ${TypeStyle.css.headlineMicro};
  color: ${o =>
    o.general ? o.theme.color.deprecated.obsidian : o.theme.color.deprecated.obsidianLighter};
  display: block;
  font-weight: 600;
  overflow-wrap: anywhere;
`

export const SneakPeeksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: ${o => o.theme.spacing(3)};
  list-style: none;
  padding: 0;
  margin: 0;
`

export const LearnMoreLink = styled.a`
  text-decoration: underline;
  color: ${o => o.theme.color.deprecated.obsidian};
  &:hover {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`
