import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_KIT_PRODUCT = gql(`
  query fetchProductKit(
    $handle: String!
    $isPreview: Boolean = false
    $includeUnavailable: Boolean = false
  ) {
    productKit(handle: $handle, isPreview: $isPreview, includeUnavailable: $includeUnavailable) {
      productGroup {
        defaultVariant {
          sku
          currencyType
          discountPrice
          discountPriceExplicitFmt
          price
          priceExplicitFmt
        }
        description
        handle
        products {
          badgeList
          collectionBadgeColor
          collectionBadgeText
          finalSale
          fitModelsDescriptions
          fit
          iconList {
            label
            title
            image
          }
          images {
            nodes {
              color
              fit
              index
              kind
              source
              type
            }
          }
          
          noindex
          rawFit
          rawMaterials
          shopifyId
          swPromoEligible
          whyWeLove
          videos {
            nodes {
              kind
              source
              type
            }
          }
        }
        seoTitle
        seoDescription
        title
      }
      collection {
        handle
        name
        productGroups {
          ...ProductGroupKitRequirements
        }
      }
    }
  }
  fragment ProductGroupKitRequirements on ProductGroup {
    category
    genderCategory
    colors {
      availability
      handle
      hexCodes
      name
      rawName
      swatchUrl
    }
    defaultVariant {
      category
      color
      currencyType
      discountPrice
      fit
      handle
      price
      rawColor
      rawFit
      shopifyId
      sku
    }
    description
    fits
    rawFits
    handle
    id
    priceRangeByColors
    embroiderable
    embroideryImages {
      color
      embroideryOffsets
      fit
      index
      source
      kind
      type
    }
    priceRange {
      min
      max
    }
    productsForAvailability: products {
      availableSizes
      colorInfo {
        rawName
      }
      rawFit
      swPromoEligible
    }
    products {
      shopifyId
      careInstructions
      collectionLinks {
        nodes {
          text
          href
        }
      }
      color
      colorInfo {
        rawName
      }
      finalSale
      fitModelsDescriptions
      fit
      handle
      images {
        nodes {
          color
          embroideryOffsets
          fit
          index
          source
          kind
          type
        }
      }
      rawFit
      rawMaterials
      rtfFeatures
      title
    }
    rtfFeatures
    sizeChart: sizeChartCG {
      ...SizeChartFragment
    }
    variants {
      color
      rawColor
      fit
      rawFit
      handle
    }
    sizes
    style
  }
`)
