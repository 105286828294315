import { HTMLAttributes } from 'react'

import { AttributeSection, Size, SizeSelector } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { SizeChartModalService } from '@syconium/magnolia/src/brunswick/containers/SizeChartModalService'
import { prettySizeName } from '@syconium/magnolia/src/lib/utils/tech-debt'
import { SizeChart } from '@syconium/magnolia/src/types/graphql'

import { FitQuizType } from '../../pages/fit-quiz/FitQuiz'

import * as Styles from './styles'

export type SizeAttributeProps = {
  title: string
  isInvalid?: boolean
  selected?: Size
  extended?: boolean
  accentColor?: string
  soldOut?: boolean
  sizeChart?: SizeChart
  sizes: Readonly<Size[]> | Size[]
  onSelect: (size: Size) => void
  className?: string
  fitQuiz?: FitQuizType
} & Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'>

export type SizeSelectionTextProps = {
  soldOut: boolean
  children: React.ReactNode
}

/**
 * The text that is displayed with the attribute selector when a size is selected.
 */
export function SizeSelectionText({ soldOut, children }: SizeSelectionTextProps) {
  const {
    detailsSection: { soldOutSectionTitleMessage },
  } = useFixturesContext()

  // Otherwise, return children + the sold out messaging
  return (
    <>
      {children} {soldOut && <Styles.SoldOut>{soldOutSectionTitleMessage}</Styles.SoldOut>}
    </>
  )
}

/**
 * An individual size attribute selector.
 */
export function SizeAttribute({
  title,
  isInvalid,
  selected,
  extended = false,
  soldOut = false,
  accentColor,
  sizeChart,
  fitQuiz,
  sizes,
  onSelect,
  className,
  ...rest
}: SizeAttributeProps) {
  const { openSizeChartModal } = SizeChartModalService.useContainer()

  const {
    sizesSection: { sizeChartCTA },
  } = useFixturesContext()

  return (
    <AttributeSection
      title={title}
      isInvalid={isInvalid}
      selection={
        selected && (
          <SizeSelectionText soldOut={soldOut}>
            {prettySizeName(selected.shorthand)}
          </SizeSelectionText>
        )
      }
      endAdornment={
        <Styles.SizeChartButton onClick={() => sizeChart && openSizeChartModal(sizeChart, fitQuiz)}>
          {sizeChartCTA}
        </Styles.SizeChartButton>
      }
      className={className}
      {...rest}
    >
      <SizeSelector
        accentColor={accentColor}
        minWidthStyle={extended ? 'lg' : 'md'}
        sizes={sizes}
        selected={selected}
        onSelectSize={onSelect}
        disableMobileHorizontalSwipe
      />
    </AttributeSection>
  )
}
