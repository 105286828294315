'use client'

import { useCallback } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { defaultDarkTheme, defaultLightTheme } from '@syconium/little-miss-figgy'
import {
  TypeStyle,
  getTypeStyle,
  isUppercase,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/themes/default/breakpoints'
import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

import { StyledTextFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { trackEvent } from '../../../../../lib/analytics'
import { NextLink } from '../../../navigation/NextLink'
import { useContentfulPageSectionContext } from '../ContentfulPageSectionProvider.client'

export const styledTextQueryFragment = gql(`
  fragment StyledTextFragment on StyledText {
    __typename  
    id
    title
    titleTextSize
    paragraph
    paragraphTextSize
    eyebrow
    textColorMobile
    textColorDesktop
    textContentMaxWidthMobile
    textContentMaxWidthDesktop
    textAlignMobile
    textAlignDesktop
    bottomMargin
    links {
      ...StyledLinkFragment
    }
  }
`)

type StyledTextElement = 'eyebrow' | 'title' | 'paragraph' | 'custom' | 'links'

type ContentfulStyledTextProps = StyledTextFragmentFragment & {
  defaultMaxWidth?: number
  defaultBottomMargin?: number
  additionalCustomContent?: React.ReactNode
  centerFloatContent?: boolean
}

// This was coded incorrectly... the toggle in Contentful was supposed to just alter a
// theme provider around all this text. Not chose "light" colored text... but the light theme.
// This wouldn't work if we used text link components with more features than just CSS color attribute.
const getThemeColor = (key: string, theme: DefaultTheme) => {
  switch (key) {
    case 'light':
      return defaultDarkTheme.color.text.primary.on.background
    case 'dark':
      return defaultLightTheme.color.text.primary.on.background
    default:
      return theme.color.text.primary.on.background
  }
}

const StyledTextOverlay = styled.div<{
  $textColorDesktop: ContentfulStyledTextProps['textColorDesktop']
  $textColorMobile: ContentfulStyledTextProps['textColorMobile']
  $textContentMaxWidthDesktop: NonNullable<ContentfulStyledTextProps['textContentMaxWidthDesktop']>
  $textContentMaxWidthMobile: NonNullable<ContentfulStyledTextProps['textContentMaxWidthMobile']>
  $textAlignMobile: ContentfulStyledTextProps['textAlignMobile']
  $textAlignDesktop: ContentfulStyledTextProps['textAlignDesktop']
  $bottomMargin: NonNullable<ContentfulStyledTextProps['bottomMargin']>
  $centerFloatContent: boolean
}>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  letter-spacing: 0.6px;
  margin-bottom: ${o => o.$bottomMargin}px;
  text-align: ${o => o.$textAlignMobile || 'center'};

  ${untilMd} {
    color: ${o =>
      o.$textColorMobile
        ? getThemeColor(o.$textColorMobile, o.theme)
        : o.theme.color.text.primary.on.background};

    > .configurable-width {
      max-width: ${o => o.$textContentMaxWidthMobile}px;
    }

    > * {
      ${o => (o.$centerFloatContent || o.$textAlignMobile !== 'left' ? 'margin-left: auto' : null)};
      ${o =>
        o.$centerFloatContent || o.$textAlignMobile !== 'right' ? 'margin-right: auto' : null};
    }
  }

  ${fromMd} {
    color: ${o =>
      o.$textColorDesktop
        ? getThemeColor(o.$textColorDesktop, o.theme)
        : o.theme.color.text.primary.on.background};
    line-height: 170%;
    text-align: ${o => o.$textAlignDesktop || 'center'};

    > .configurable-width {
      max-width: ${o => o.$textContentMaxWidthDesktop}px;
    }

    > * {
      ${o =>
        o.$centerFloatContent || o.$textAlignDesktop !== 'left' ? 'margin-left: auto' : null};
      ${o =>
        o.$centerFloatContent || o.$textAlignDesktop !== 'right' ? 'margin-right: auto' : null};
    }
  }
`

type ContentfulStyledTextTitleProps = {
  className: string
  children?: React.ReactNode
  isUppercase: boolean
  textAlignMobile: ContentfulStyledTextProps['textAlignMobile']
  textAlignDesktop: ContentfulStyledTextProps['textAlignDesktop']
  titleTextSize: ContentfulStyledTextProps['titleTextSize']
  includeBottomMargin: boolean
}

export const ContentfulStyledTextTitle = styled(
  ({ className, children, isUppercase, titleTextSize }: ContentfulStyledTextTitleProps) => {
    const typeStylesMap: Record<
      ContentfulStyledTextProps['titleTextSize'],
      ReturnType<typeof getTypeStyle>
    > = {
      h1: getTypeStyle('headlineExtraLarge'),
      h2: getTypeStyle('headlineLarge'),
      h3: getTypeStyle('headlineMedium'),
      h4: getTypeStyle('headlineSmall'),
    } as const
    const TypeStyleSelection = typeStylesMap[titleTextSize ?? 'h3']
    return (
      <TypeStyleSelection asTag={titleTextSize} className={className} isUppercase={isUppercase}>
        {children}
      </TypeStyleSelection>
    )
  }
)`
  ${o => (o.includeBottomMargin ? 'margin-bottom: 16px;' : null)}
  text-align: ${o => o.textAlignMobile || 'center'};

  ${fromMd} {
    display: block;
    text-align: ${o => o.textAlignDesktop || 'center'};
    ${o => (o.includeBottomMargin ? 'margin-bottom: 24px;' : null)}
  }
`

const StyledTextEyebrow = styled.h3<{ $includeBottomMargin: boolean }>`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.25em;
  ${o => (o.$includeBottomMargin ? 'margin-bottom: 16px;' : null)}
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
    ${o => (o.$includeBottomMargin ? 'margin-bottom: 24px;' : null)}
  }
`

const StyledTextLinks = styled.div<{
  $textAlignMobile: ContentfulStyledTextProps['textAlignMobile']
  $textAlignDesktop: ContentfulStyledTextProps['textAlignDesktop']
}>`
  margin-top: ${o => o.theme.spacing(6)};
  margin-bottom: ${o => o.theme.spacing(6)};

  a {
    margin-top: ${o => o.theme.spacing(6)};
    margin-bottom: ${o => o.theme.spacing(6)};
    margin-left: ${o => o.theme.spacing(3)};
    margin-right: ${o => o.theme.spacing(3)};
  }

  text-align: ${o => o.$textAlignMobile || 'center'};

  ${untilMd} {
    > :first-child {
      margin-top: 0px;
      margin-left: ${o => o.$textAlignMobile === 'left' && '0'};
    }

    > :last-child {
      margin-bottom: 0px;
      margin-right: ${o => o.$textAlignMobile === 'right' && '0'};
    }
  }

  ${fromMd} {
    text-align: ${o => o.$textAlignDesktop || 'center'};

    > :first-child {
      margin-top: 0px;
      margin-left: ${o => o.$textAlignDesktop === 'left' && '0'};
      margin-right: ${o => o.$textAlignDesktop === 'right' && '0'};
    }

    > :last-child {
      margin-bottom: 0px;
      margin-right: ${o => o.$textAlignDesktop === 'right' && '0'};
    }
  }

  a {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 23px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
      border-bottom: 2px solid currentColor;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`

const StyledTextParagraph = styled.p<{
  $paragraphTextSize: ContentfulStyledTextProps['paragraphTextSize']
  $includeBottomMargin: boolean
}>`
  ${o => (o.$includeBottomMargin ? 'margin-bottom: 16px;' : null)}
  ${fromMd} {
    ${o => (o.$includeBottomMargin ? 'margin-bottom: 24px;' : null)}
  }
  ${o => TypeStyle.css[o.$paragraphTextSize]}
`

export const ContentfulStyledText = ({
  defaultMaxWidth = 9999,
  defaultBottomMargin = 0,
  title,
  titleTextSize,
  paragraph,
  paragraphTextSize,
  eyebrow,
  textColorMobile,
  textColorDesktop,
  textContentMaxWidthMobile,
  textContentMaxWidthDesktop,
  textAlignMobile,
  textAlignDesktop,
  bottomMargin,
  links,
  additionalCustomContent,
  centerFloatContent,
}: ContentfulStyledTextProps) => {
  const sectionContext = useContentfulPageSectionContext()

  const includeBottomMargin = useCallback(
    (element: StyledTextElement) => {
      const includedElements: StyledTextElement[] = []
      if (eyebrow) includedElements.push('eyebrow')
      if (title) includedElements.push('title')
      if (paragraph) includedElements.push('paragraph')
      if (additionalCustomContent) includedElements.push('custom')
      if (links.length > 0) includedElements.push('links')
      const indexOfElement = includedElements.indexOf(element)
      return indexOfElement > -1 && indexOfElement < includedElements.length - 1
    },
    [additionalCustomContent, eyebrow, links.length, paragraph, title]
  )

  return (
    <StyledTextOverlay
      $textColorDesktop={textColorDesktop}
      $textColorMobile={textColorMobile}
      $textContentMaxWidthDesktop={textContentMaxWidthDesktop ?? defaultMaxWidth}
      $textContentMaxWidthMobile={textContentMaxWidthMobile ?? defaultMaxWidth}
      $textAlignMobile={textAlignMobile}
      $textAlignDesktop={textAlignDesktop}
      $bottomMargin={bottomMargin ?? defaultBottomMargin}
      $centerFloatContent={centerFloatContent ?? false}
    >
      {eyebrow && (
        <StyledTextEyebrow
          $includeBottomMargin={includeBottomMargin('eyebrow')}
          className='configurable-width'
        >
          {eyebrow}
        </StyledTextEyebrow>
      )}

      {title && (
        <ContentfulStyledTextTitle
          titleTextSize={titleTextSize}
          className='configurable-width'
          isUppercase={isUppercase(title)}
          textAlignMobile={textAlignMobile}
          textAlignDesktop={textAlignDesktop}
          includeBottomMargin={includeBottomMargin('title')}
        >
          {title}
        </ContentfulStyledTextTitle>
      )}

      {paragraph && (
        <StyledTextParagraph
          $includeBottomMargin={includeBottomMargin('paragraph')}
          $paragraphTextSize={paragraphTextSize ?? 'bodyDefault'}
          className='configurable-width'
        >
          {paragraph}
        </StyledTextParagraph>
      )}

      {additionalCustomContent}

      {links.length > 0 && (
        <StyledTextLinks
          $textAlignMobile={textAlignMobile}
          $textAlignDesktop={textAlignDesktop}
          className='configurable-width'
        >
          {links.map((link, index) => (
            <NextLink
              key={`${link.id}-${index}`}
              href={link.url}
              {...trackEvent({
                category: sectionContext.pageSectionAnalyticsName,
                action: `click cta ${index}`,
                label: link.text,
                value: link.url,
              })}
            >
              {link.text}
            </NextLink>
          ))}
        </StyledTextLinks>
      )}
    </StyledTextOverlay>
  )
}
