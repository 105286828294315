import { gql } from '../../../__generated__/graphql/catalog'

export const GET_CUSTOMER_SIZE_PROFILE = gql(`
    query GetCustomerSizeProfile ($id: ID!) {
        getCustomerSizeProfile(id: $id) {
            gender
            age
            height
            weight
            recommendations {
                category
                size
                fit
            }
        }
    }
`)
