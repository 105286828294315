export enum FieldType {
  Size = 'size',
  Length = 'length',
  Weight = 'weight',
  Height = 'height',
  Fit = 'fit',
  Occupation = 'occupation',
  MediaType = 'mediaType',
}

export enum FieldValueType {
  Single = 'single',
  Range = 'range',
}

export enum ProductTag {
  Tops = 'tops',
  Bottoms = 'bottoms',
  Others = 'other',
}

interface MapFieldTypeToType {
  [FieldValueType.Single]: {}
  [FieldValueType.Range]: { min?: number; max: number } | { min: number; max?: number }
}

interface MapFieldTypeToValueType {
  [FieldType.Size]: MapFieldTypeToType[FieldValueType.Single]
  [FieldType.Length]: MapFieldTypeToType[FieldValueType.Single]
  [FieldType.Weight]: MapFieldTypeToType[FieldValueType.Range]
  [FieldType.Height]: MapFieldTypeToType[FieldValueType.Single]
  [FieldType.Fit]: MapFieldTypeToType[FieldValueType.Single]
  [FieldType.Occupation]: MapFieldTypeToType[FieldValueType.Single]
  [FieldType.MediaType]: MapFieldTypeToType[FieldValueType.Single]
}

export type FieldValue<FT extends FieldType> = MapFieldTypeToValueType[FT] & {
  name: string
  value: string | string[]
  // min and max are only for `FieldType.Weight`
  min?: number
  max?: number
}

export interface Field<FT extends FieldType> {
  fieldId: number
  name: string
  type: FT
  values: Array<FieldValue<FT>>
}

export type AnyField =
  | Field<FieldType.Size>
  | Field<FieldType.Length>
  | Field<FieldType.Weight>
  | Field<FieldType.Height>
  | Field<FieldType.Fit>
  | Field<FieldType.Occupation>
  | Field<FieldType.MediaType>
