import { SiteFixtures, SizeChart, SizeChartTableCells, SizeChartTip } from '../../types/graphql'
import { SizeChartTableProps } from '../components/size-chart/SizeChartTable'

export const measureTips: Array<SizeChartTip> = [
  { kind: 'BUST', tip: 'Measure around the fullest part of your bust' },
  { kind: 'WAIST', tip: 'Measure around the smallest part of your waist' },
  { kind: 'HIP', tip: 'Measure around the fullest part of your hip' },
]

export const sizeChartTableCells: SizeChartTableProps['cells'] = JSON.stringify({
  inches: [
    ['Size', 'XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL'],
    ['Bust', '31-32', '33 ½', '35-36', '37-38', '39-40', '41-43', '44-46'],
    ['Waist', '23-24', '25-26 ½', '27-28.5', '29-31', '32-34', '35-37', '38-40'],
    ['Hip', '33-34', '35-36 ½', '37-38.5', '39-41', '42-43', '44-46', '47-49'],
    ['US', '00-0', '0-2', '4-6', '8-10', '12', '14', '16'],
    ['CA / AU / NZ', '00-0', '0-2', '4-6', '8-10', '12', '14', '16'],
  ],
  centimeters: [
    ['Size', 'XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL'],
    ['Bust', '61-62', '63 ½', '65-66', '67-68', '69-60', '61-63', '64-66'],
    ['Waist', '43-24', '45-46 ½', '47-48.5', '49-31', '62-64', '65-67', '68-80'],
    ['Hip', '3-64', '65-66 ½', '67-68.5', '69-81', '82-83', '84-86', '87-89'],
    ['US', '00-0', '0-4', '8-12', '16-20', '24', '28', '32'],
    ['CA / AU / NZ', '00-0', '0-4', '8-12', '16-20', '24', '28', '32'],
  ],
})

export const sizeChartTableCellsNonMeasurement: SizeChartTableCells = [
  ['Size', 'Petite'],
  ['Catarina', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
  ['Casma', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
  ['Rafaela', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
  ['Ngara', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
  ['Kitale Maternity', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
  ['Juba', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'],
]

export const sizeChartTable: SizeChartTableProps = {
  cells: JSON.stringify(sizeChartTableCells),
  measureImage:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/women-sizing_600x_87e25906-653f-42d0-990a-0ca153bc6d1d_600x.png',
  measureTips,
  sizeTips: [],
  subtitle: 'Some text explaining something about the measurements below',
  tabLabel: 'Tops',
  title: 'Women’s Tops',
}

export const sizeChartTableNonMeasurement: SizeChartTableProps = {
  cells: JSON.stringify(sizeChartTableCellsNonMeasurement),
  measureImage: null,
  measureTips: [],
  sizeTips: [],
  subtitle: null,
  tabLabel: 'Style Guide',
  title: 'Women’s Tops Style Guide',
}

export const sizeChartTableOther: SizeChartTableProps = {
  cells: JSON.stringify(sizeChartTableCells),
  measureImage: null,
  measureTips: [],
  sizeTips: [],
  subtitle: null,
  tabLabel: 'Inseam',
  title: 'Other',
}

export const sizeChart: SizeChart = {
  columnNames: ['FIGS SIZE'],
  columnValues: [['XXS', '0', '31-32', '23-24', '33-34', '23-24']],
  imageUri:
    '//cdn.shopify.com/s/files/1/0139/8942/files/women-sizing_600x_87e25906-653f-42d0-990a-0ca153bc6d1d_600x.png?v=1527206822',
  navLabel: 'Tops',
  tips: [
    {
      kind: 'Hip',
      tip: 'Measure around the fullest part of your hip',
    },
  ],
  title: 'size chart title',
  tables: [sizeChartTable, sizeChartTableNonMeasurement, sizeChartTableOther],
  video: null,
  __typename: 'SizeChartCG',
  name: '',
}

export const sizeChartPants: SizeChart = {
  navLabel: 'Pants',
  title: 'Women’s Pants',
  tables: [],
  video: null,
  __typename: 'SizeChartCG',
  columnNames: null,
  columnValues: null,
  imageUri: null,
  name: '',
  tips: null,
}

export const sizeChartLabCoats: SizeChart = {
  navLabel: 'Lab Coats',
  title: 'Women’s Lab Coats',
  tables: [sizeChartTable],
  video: null,
  __typename: 'SizeChartCG',
  columnNames: null,
  columnValues: null,
  imageUri: null,
  name: '',
  tips: null,
}

export const sizeChartSocks: SizeChart = {
  navLabel: 'Socks',
  title: 'Men’s Ankle Socks',
  tables: [sizeChartTable],
  video: null,
  __typename: 'SizeChartCG',
  columnNames: null,
  columnValues: null,
  imageUri: null,
  name: '',
  tips: null,
}

export const sizeChartMasks: SizeChart = {
  navLabel: 'Masks',
  title: 'Masks',
  tables: [],
  video: null,
  __typename: 'SizeChartCG',
  columnNames: null,
  columnValues: null,
  imageUri: null,
  name: '',
  tips: null,
}

export const sizeChartShoes: SizeChart = {
  navLabel: 'Shoes',
  title: 'Women’s Shoes',
  tables: [],
  video: null,
  __typename: 'SizeChartCG',
  columnNames: null,
  columnValues: null,
  imageUri: null,
  name: '',
  tips: null,
}

export const womensSizeCharts: SiteFixtures['womensSizeCharts'] = [
  sizeChart,
  sizeChartLabCoats,
  sizeChartMasks,
  sizeChartPants,
  sizeChartShoes,
  sizeChartSocks,
]

export const mensSizeCharts: SiteFixtures['mensSizeCharts'] = [
  sizeChart,
  sizeChartLabCoats,
  sizeChartMasks,
  sizeChartPants,
  sizeChartShoes,
  sizeChartSocks,
]
