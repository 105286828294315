import styled from 'styled-components'

import { TextLink, TypeStyle, atXs, fromMd } from '@syconium/little-miss-figgy'

export const Header = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  padding: 20px 16.5px;
  position: sticky;
  top: 0;
  z-index: ${o => o.theme.zIndex.foregroundContent};

  svg {
    path {
      stroke-width: 1;
    }
  }

  ${fromMd} {
    padding: 20px 40px;
  }

  ${atXs} {
    padding: 0 16.5px;
  }
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)`
  margin-inline-end: 6px;
`

export const ItemCount = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-inline-start: 6px;
`

export const EmptyCartMessage = styled.span`
  display: block;
  font-size: 13px;
  font-weight: bold;
  margin: 12px 0;
  text-align: center;
`

export const KeepShoppingContainer = styled.div`
  padding: 0 16.5px;
  background: white;

  ${o => o.theme.device.fromMd} {
    padding: 0 40px;
  }
`

export const KeepShopping = styled.div`
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  align-items: center;
  background: white;
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const KeepShoppingLink = styled(TextLink)`
  ${TypeStyle.css.bodyDefault};
  border-bottom: solid;
  color: ${o => o.theme.color.deprecated.obsidian};
  text-transform: uppercase;
  line-height: 1em;
  font-weight: bold;
  padding-bottom: 2px;
`

export const MiniCartMessage = styled.p`
  ${TypeStyle.css.headlineMicro};
  margin-bottom: 10px;
`

export const TotalSection = styled.div`
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 18px;
  padding: 20px 16.5px;

  ${fromMd} {
    padding: 20px 40px;
  }
`

export const TotalLine = styled.div`
  display: flex;
  height: 23px;
  align-items: center;
  justify-content: space-between;
`

export const TotalLabel = styled.span`
  color: ${o => o.theme.color.deprecated.obsidian};
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
`

export const TotalAmount = styled.span`
  ${TypeStyle.css.headlineExtraSmall};
`

export const CheckoutButtonWrapper = styled.div`
  padding: 0 16.5px 20px;

  ${fromMd} {
    padding: 0 40px 20px;
  }
`

export const EmbroideryWarningContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`
