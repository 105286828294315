import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const ORDER_LINE_ITEMS = gql(`
  query getOrderLineItems($id: String!) {
    my {
      id
      order(id: $id) {
        lineItems {
          nodes {
            id
            image {
              url
            }
            fulfillmentStatus
            title
          }
        }
      }
    }
  }
`)
