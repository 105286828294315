import styled from 'styled-components'

import {
  AccordionStyles,
  Button,
  TypeStyle,
  TypeStyleBodyMedium,
  TypeStyleHeadlineExtraExtraSmall,
  TypeStyleHeadlineMicro,
  fromMd,
  typeStyleMap,
  untilMd,
} from '@syconium/little-miss-figgy'

import { ContentfulStyledText } from '../../contentful/ContentfulStyledText/ContentfulStyledText'

export const Body = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  padding: ${o => o.theme.spacing(6)} ${o => o.theme.spacing(4)};
  ${untilMd} {
    padding-top: 0;
  }
  ${fromMd} {
    width: 600px;
    padding-top: 4px;
  }
`

export const LoadingBody = styled.div`
  margin-top: ${o => o.theme.spacing(12)};
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(6)};
  justify-content: center;
  align-items: center;
`
export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StepText = styled(TypeStyleHeadlineMicro)`
  ${o => o.theme.typography.effect.uppercase}
  font-weight: 700;
`
export const ProgressContainer = styled.div`
  height: 6px;
  width: 100%;
  margin-top: ${o => o.theme.spacing(2)};
  margin-bottom: ${o => o.theme.spacing(6)};
`
export const ButtonContainer = styled.div`
  display: flex;
  ${untilMd} {
    flex-direction: column;
  }
  margin-bottom: ${o => o.theme.spacing(6)};
`
export const NoDisplayInput = styled.input`
  display: none;
`

export const FitQuizPageHeader = styled.div`
  padding: ${o => o.theme.spacing(0, 4, 6, 4)};
  ${typeStyleMap['headlineMedium']}
  ${fromMd} {
    width: 600px;
    margin: auto;
    ${typeStyleMap['headlineSmall']}
  }
`
export const AccordionTitle = styled(TypeStyleHeadlineMicro)`
  ${o => o.theme.typography.effect.uppercase}
  font-weight: 700;
  text-align: start;
  text-transform: uppercase;
`
export const AccordionContainer = styled.div`
  margin-top: ${o => o.theme.spacing(6)};
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};

  ${fromMd} {
    padding: 0;
  }

  ${AccordionStyles.BodyContainer} {
    padding-left: 0;
    padding-right: 0;
  }

  ${AccordionStyles.HeaderContainer} {
    padding: 20px 5px 20px 0;

    ${fromMd} {
      padding: 24px 0;
    }
  }
`

export const SizeRecContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(5)};
  margin-top: ${o => o.theme.spacing(4)};
`

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(5)};
  margin-top: ${o => o.theme.spacing(4)};
`

export const AccordionSizeRecContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(5)};
`
export const ModalBody = styled.div`
  overflow: auto;
  ${fromMd} {
    height: 768px;
  }
  ${Body} {
    padding-top: ${o => o.theme.spacing(6)};
  }
`

export const RecommendationButtonsContainer = styled.div`
  margin-top: ${o => o.theme.spacing(6)};
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
`

export const ErrorButtonsContainer = styled.div`
  padding-top: ${o => o.theme.spacing(6)};
  border-top: ${o => o.theme.color.stroke.neutral.border.decoration.on.background} 1px solid;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
`

export const ExitLink = styled(Button)`
  flex-grow: 1;
`

export const ExitModalButton = styled(Button)`
  flex-grow: 1;
`
export const RestartButton = styled(Button)`
  flex-grow: 1;
`

export const TextLink = styled.a`
  text-decoration: underline;
`

export const CallToLogin = styled(TypeStyle.BodyDefault)`
  margin-top: ${o => o.theme.spacing(4)};
`

export const SmallHeaderContentfulStyledText = styled(ContentfulStyledText)`
  > h1,
  h2,
  h3,
  h4 {
    font-size: 14px;
    margin-bottom: 8px;
  }
`

export const LargeCategoryLabel = styled(TypeStyleHeadlineExtraExtraSmall)`
  ${o => o.theme.typography.effect.uppercase}
  color: ${o => o.theme.color.text.primary.on.background};
  padding-bottom: 16px;
  ${fromMd} {
    padding-bottom: 24px;
  }
`

export const ErrorTitle = styled(TypeStyleBodyMedium)`
  margin-bottom: ${o => o.theme.spacing(2)};
  font-weight: 700;
`

export const ErrorDescription = styled(TypeStyleBodyMedium)`
  font-weight: 400;
`
