'use client'

import {
  DetailedHTMLProps,
  FC,
  SyntheticEvent,
  VideoHTMLAttributes,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react'
import styled, { keyframes } from 'styled-components'

import { ResponsiveImage as LMFResponsiveImage } from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { fromLg, fromMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { timingSlower } from '@syconium/little-miss-figgy/dist/constants/timing'

import { RenderOnceInViewport } from '../performance/RenderOnceInViewport.client'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const ResponsiveImage = styled(LMFResponsiveImage)`
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
`

const StyledVideo = styled.video<{
  $aspectRatioSm: number | null
  $aspectRatioMd: number | null
  $aspectRatioLg: number | null
}>`
  width: 100%;
  max-width: 100%;
  object-fit: cover;

  ${props => (props.$aspectRatioSm !== null ? `aspect-ratio: ${props.$aspectRatioSm};` : undefined)}
  ${fromMd} {
    ${props =>
      props.$aspectRatioMd !== null ? `aspect-ratio: ${props.$aspectRatioMd};` : undefined}
  }
  ${fromLg} {
    ${props =>
      props.$aspectRatioLg !== null ? `aspect-ratio: ${props.$aspectRatioLg};` : undefined}
  }
`

const VideoContainer: FC<{ isLazy?: boolean; children?: React.ReactNode }> = ({
  children,
  isLazy = false,
}) => (isLazy ? <RenderOnceInViewport>{children}</RenderOnceInViewport> : <>{children}</>)

export const MaxIterationsVideo = forwardRef<
  HTMLVideoElement,
  {
    imageWhenComplete?: string
    isLazy?: boolean
    maxIterations: number
    aspectRatios: {
      sm: number | null
      md: number | null
      lg?: number | null
    } | null
  } & DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
>(({ imageWhenComplete, isLazy = false, maxIterations = 5, aspectRatios, ...rest }, ref) => {
  const numberOfPlays = useRef<number>(1)
  const [showImage, setShowImage] = useState<boolean>(false)

  const replayVideo = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      const videoElement = event.target as HTMLVideoElement
      if (numberOfPlays.current < maxIterations) {
        videoElement.play()
        numberOfPlays.current++
      } else {
        setShowImage(true)
      }
    },
    [maxIterations, numberOfPlays]
  )

  return (
    <>
      {showImage && imageWhenComplete ? (
        <ResponsiveImage
          className={rest.className}
          src={imageWhenComplete}
          alt=''
          aspectRatios={{
            sm: null,
            md: null,
          }}
          loading='default'
          widths={{ unit: 'vw', sm: 100, md: 100 }}
        />
      ) : (
        <VideoContainer isLazy={isLazy}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <StyledVideo
            ref={ref}
            $aspectRatioSm={aspectRatios?.sm ?? null}
            $aspectRatioMd={aspectRatios?.md ?? null}
            $aspectRatioLg={aspectRatios?.lg ?? null}
            onEnded={replayVideo}
            {...rest}
          />
        </VideoContainer>
      )}
    </>
  )
})
