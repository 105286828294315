import styled from 'styled-components'

import { TypeStyle, fromMd, timingSlower } from '@syconium/little-miss-figgy'

import { miniCartWidthDesktop, miniCartWidthMobile } from '../../constants'

export const Container = styled.div<{
  isRevealed: boolean
}>`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  bottom: 0;
  overflow: auto;
  position: fixed;
  inset-inline-end: 0;
  top: 0;
  width: ${miniCartWidthMobile};
  transform: translate3d(${o => (o.isRevealed ? '0' : '100%')}, 0, 0);
  transition: transform ${timingSlower} ease;
  z-index: ${o => o.theme.zIndex.modals};

  .rtl & {
    transform: translate3d(${o => (o.isRevealed ? '0' : '-100%')}, 0, 0);
  }

  ${fromMd} {
    width: ${miniCartWidthDesktop};
  }
`

export const CartItemsWrapper = styled.div`
  background: white;
  padding: 0 16.5px 0 16.5px;

  ${fromMd} {
    padding: 0 40px 0 40px;
  }
`

export const CloseButton = styled.button`
  background: unset;
  border: unset;
  bottom: auto;
  cursor: pointer;
  inset-inline-end: 0;
  padding: 20px;
  position: absolute;
  top: auto;

  ${fromMd} {
    inset-inline-end: 20px;
    &:hover g {
      stroke: ${o => o.theme.color.deprecated.obsidianLighter};
    }
  }
`

export const TotalAndCheckout = styled.div`
  background-color: white;
  bottom: 0;
  position: sticky;
  z-index: 10000;

  // to not affect iphone SE
  @media screen and (max-width: 374px) {
    position: static;
  }
`

export const CheckoutErrorMessage = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.deprecated.red.error};
  font-weight: bold;
  margin-top: 10px;
`

export const BuyNowPayLaterContainer = styled.div`
  background-color: white;
  padding-bottom: 20px;
`

export const IntlMsgWrap = styled(TypeStyle.HeadlineMicro)`
  color: ${o => o.theme.color.deprecated.obsidianLight};
  margin-top: ${o => o.theme.spacing(2)};
  text-align: right;
`

export const TotalLineItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(4)};
  ${fromMd} {
    gap: ${o => o.theme.spacing(5)};
  }
`

export const PortalDiscountText = styled(TypeStyle.BodyLarge)`
  color: ${o => o.theme.color.deprecated.red.dark};
  font-weight: 700;
`
