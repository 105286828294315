import { initialize } from 'launchdarkly-js-client-sdk'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import { cookieKeys } from '../../app/_config/Cookies.config'
import {
  createLaunchDarklyClientConfig,
  createLaunchDarklyContext,
} from '../../app/_config/Experimentation.config'
import {
  createNewSessionId,
  getSessionAttributesFromCookies,
} from '../../app/_config/Session.config'
import { useCookies } from '../../app/_providers/CookiesProvider.client'
import { HeapTrackContainer } from '../../lib/analytics/hooks/HeapTrackContainer'
import { AuthenticationContainer } from '../AuthenticationContainer'

const createClientForUser = ({
  user,
  sessionAttributes,
}: {
  user: ReturnType<typeof AuthenticationContainer.useContainer>['currentUser']
  sessionAttributes: ReturnType<typeof getSessionAttributesFromCookies>
}) => {
  if (typeof window === 'undefined') return undefined
  if (!process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY) return undefined

  const context = createLaunchDarklyContext({
    user: user?.id
      ? {
          id: user.id,
          email: user.email,
          profile: {
            firstName: user.firstName ?? 'FIGS Customer',
            lastName: user.lastName ?? undefined,
          },
          orders: {
            count: user.ordersCount ?? 0,
          },
        }
      : undefined,
    sessionAttributes,
  })

  const config = createLaunchDarklyClientConfig({ bootstrap: undefined })

  return initialize(process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY, context, config)
}

const useExperimentationContainerImpl = () => {
  const router = useRouter()
  const { currentUser } = AuthenticationContainer.useContainer()
  const [cookies, setCookie] = useCookies([
    cookieKeys.initialSessionIndicator.key,
    cookieKeys.stylePreference.key,
    cookieKeys.priorPageViewIndicator.key,
    cookieKeys.sessionId.key,
  ])

  // Deconstruct cookies to make them safer in dependency arrays.
  const initialSessionCookie = cookies[cookieKeys.initialSessionIndicator.key]
  const stylePreferenceCookie = cookies[cookieKeys.stylePreference.key]
  const priorPageViewCookie = cookies[cookieKeys.priorPageViewIndicator.key]
  const sessionIdCookie = cookies[cookieKeys.sessionId.key]

  // Purposefully do not make this reactive. It is just a stable and private data store for meta info for this container.
  const [_internals] = useState<{
    disabledFeatures: Record<string, true>
    synchronousFlagsDisabled: boolean
  }>(() => {
    return {
      disabledFeatures: {},
      synchronousFlagsDisabled: true,
    }
  })

  // We are only supporting clientside testing and cookie setting for pages directory. So bail if no window.
  const sessionAttributes = useMemo(() => {
    if (typeof window === 'undefined') return undefined

    let sessionId = sessionIdCookie
    if (sessionId === undefined) {
      sessionId = createNewSessionId()
      setCookie(cookieKeys.sessionId.key, sessionId, cookieKeys.sessionId.options)
    }
    return getSessionAttributesFromCookies({
      initialSessionCookie,
      stylePreferenceCookie,
      priorPageViewCookie,
      sessionIdCookie: sessionId,
    })
  }, [initialSessionCookie, priorPageViewCookie, sessionIdCookie, setCookie, stylePreferenceCookie])

  const [isClientReady, setIsClientReady] = useState(false)
  const client = useMemo(() => {
    if (!sessionAttributes) return

    return createClientForUser({
      user: currentUser,
      sessionAttributes,
    })
  }, [currentUser, sessionAttributes])

  // If the user interacts with things, enable the client to check new flags.
  useEffect(() => {
    if (_internals.synchronousFlagsDisabled && isClientReady) {
      const interactionCallback = () => {
        _internals.synchronousFlagsDisabled = false
      }
      router.events.on('routeChangeStart', interactionCallback)
      window.addEventListener('mousedown', interactionCallback)
      window.addEventListener('touchstart', interactionCallback)
      window.addEventListener('keydown', interactionCallback)
      return () => {
        window.removeEventListener('mousedown', interactionCallback)
        window.removeEventListener('touchstart', interactionCallback)
        window.removeEventListener('keydown', interactionCallback)
        router.events.off('routeChangeStart', interactionCallback)
      }
    } else {
      return
    }
  }, [_internals, isClientReady, router.events])

  useEffect(() => {
    client
      ?.waitUntilReady()
      .then(() => {
        setIsClientReady(true)
      })
      .catch(() => {})
  }, [_internals, client])

  return {
    client: isClientReady ? client : undefined,
    _internals,
  }
}

const ExperimentationContainer = createContainer(useExperimentationContainerImpl)
export const PagesDirectoryExperimentationContainerProvider = ExperimentationContainer.Provider

export function useFeatureFlag(options: {
  key: string
  defaultVariant: string
  forceSync: true
  skip?: boolean
}): string

export function useFeatureFlag(options: {
  key: string
  defaultVariant: string
  forceSync: false
  skip?: boolean
}): null | string

export function useFeatureFlag({
  key,
  defaultVariant,
  forceSync,
  skip,
}: {
  key: string
  defaultVariant: string
  forceSync: boolean
  skip?: boolean
}) {
  const { client, _internals } = ExperimentationContainer.useContainer()
  const { sendHeapEvent } = HeapTrackContainer.useContainer()

  if (forceSync && _internals.synchronousFlagsDisabled) {
    _internals.disabledFeatures[key] = true
  }

  if (_internals.disabledFeatures[key]) {
    return defaultVariant
  }

  if (skip === true) {
    return defaultVariant
  }

  if (!client) {
    if (forceSync) {
      return defaultVariant
    } else {
      return null
    }
  }

  const decision = `${client.variation(key, defaultVariant)}`

  sendHeapEvent({
    eventName: `launch-darkly-feature-flag-${key}`,
    props: {
      [`launch-darkly-feature-flag-${key}-decision`]: decision,
    },
  })

  return decision
}
