import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_PRODUCT_SIZE_CHART = gql(`
  query getProductGroups(
    $handle: String
    $isPreview: Boolean = false
    $includeUnavailable: Boolean = false
  ) {
    productGroups(handle: $handle, isPreview: $isPreview, includeUnavailable: $includeUnavailable) {
      nodes {
        title
        seoTitle
        sizeChart: sizeChartCG {
          ...SizeChartFragment
        }
      }
    }
  }
`)
