import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import {
  SelectorButton,
  TypeStyle,
  TypeStyleBodyMicro,
  TypeStyleBodyNano,
} from '@syconium/little-miss-figgy'

import { Button } from '../../../../brunswick/components/CtaButton/styles'

export const TextInput = styled.input<{ hasError?: boolean }>`
  ${TypeStyle.css.bodySmall}
  background: ${o => o.theme.color.input.empty.fill}
  border-radius: ${o => o.theme.spacing(1)};
  border: 1px solid
    ${o =>
      o.hasError
        ? o.theme.color.text.critical.on.background
        : o.theme.color.text.primary.on.background};
  box-sizing: border-box;
  height: ${o => o.theme.spacing(10)};
  padding: ${o => o.theme.spacing(2, 8, 2, 4)};
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`
export const UnitLabel = styled.legend`
  ${TypeStyle.css.bodyMicro}
  position: absolute;
  top: 10px;
  right: ${o => o.theme.spacing(4)};
  z-index: 2;
  color: ${o => o.theme.color.stroke.neutral.text.tertiary.on.background};
`

export const TextInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${o => o.theme.spacing(10)};
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(6)};
  padding: ${o => o.theme.spacing(6)} 0;
`

export const StyledSelectorButton = styled(SelectorButton)`
  min-width: 96px;
  width: 50%;
  height: 50px;
  margin: 0;
`

export const SelectorButtonContainer = styled.fieldset`
  display: flex;
  gap: ${o => o.theme.spacing(2)};
  width: 100%;
`

export const ButtonInputs = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: ${o => o.theme.spacing(6)};
  column-gap: ${o => o.theme.spacing(2)};
  flex-wrap: wrap;
`

export const Label = styled.legend`
  ${TypeStyle.css.headlineMicro}
  ${o => o.theme.typography.effect.uppercase}
  font-weight: 700;
  margin-bottom: ${o => o.theme.spacing(2)};
  text-transform: uppercase;
`

export const ToggleInputContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const InputContainer = styled.fieldset`
  display: flex;
  flex-direction: column;
`
export const SplitItemsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${o => o.theme.spacing(2)};
`

export const Divider = styled.div`
  border-bottom: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  width: 100%;
`

export const CompleteFormButton = styled(Button)<ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 50%;
  :focus-within {
    outline: none;
    border: 2px solid black;
  }
  outline: none;
  border: 2px solid black;
  padding: ${o => o.theme.spacing(1, 1)};
`

export const GenderLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
`
export const ErrorText = styled(TypeStyleBodyMicro)`
  color: ${o => o.theme.color.text.critical.on.background};
  margin-top: ${o => o.theme.spacing(2)};
`

export const SelectedGenderLabel = styled(TypeStyleBodyNano)`
  font-size: 12px;
  margin-bottom: ${o => o.theme.spacing(2)};
`
export const TermAgreementText = styled(TypeStyleBodyNano)``

export const TermLink = styled.a`
  text-decoration: underline;
`
