import { useState } from 'react'
import { createContainer } from 'unstated-next'

const useFitQuizModalState = () => {
  const [isFitQuizModalOpen, setIsFitQuizModalOpen] = useState<boolean>(false)

  return {
    isFitQuizModalOpen,
    setIsFitQuizModalOpen,
  }
}

export const FitQuizModalStateContainer = createContainer(useFitQuizModalState)
