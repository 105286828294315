/**
 * This hook wraps the region definitions and utilities and synchronizes
 * provides the region/locale session data in a way that is observable
 * by React components.
 */
import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

import {
  SupportedLanguageGroupIdentifier,
  SupportedLocaleIdentifier,
  isValidLanguageGroup,
  languageNamesByLocale,
  retrieveRegion,
  supportedLocaleIdentifiers,
  supportedRegions,
} from '@syconium/weeping-figs'
import { ShopRegion, isValidLocale, retrieveLanguageGroupsForRegion } from '@syconium/weeping-figs'

import { cookieKeys } from '../../../app/_config/Cookies.config'
import { useCookies } from '../../../app/_providers/CookiesProvider.client'

const regions = Object.entries(supportedRegions)
  .filter(([k, _]) => k !== 'default')
  .map(([_, region]) => region)
  .sort((a, b) => a.name.localeCompare(b.name))

type UseLocalizationProps =
  | {
      requestIncludedLocalizationPathParam: boolean
    }
  | undefined
function useLocalization(initialState: UseLocalizationProps) {
  const [cookies, setCookie] = useCookies([cookieKeys.locale.key, cookieKeys.region.key])

  const [region] = useState(() => {
    return retrieveRegion({
      countryCode: cookies[cookieKeys.region.key] ?? '',
      defaultFallback: true,
    })
  })

  const [locale] = useState<SupportedLocaleIdentifier>(() => {
    const contextLocale = cookies[cookieKeys.locale.key] ?? ''
    if (isValidLocale(contextLocale)) {
      return contextLocale
    } else {
      return region.defaultLocale
    }
  })

  const [languageGroup] = useState<SupportedLanguageGroupIdentifier>(() => {
    const languageForLocaleAndRegion = retrieveLanguageGroupsForRegion(region, {
      locale,
    })
    const defaultLanguageGroupForRegion = region.defaultLocale.split('-')[0] ?? 'en'

    if (languageForLocaleAndRegion[0]) return languageForLocaleAndRegion[0]
    if (isValidLanguageGroup(defaultLanguageGroupForRegion)) return defaultLanguageGroupForRegion
    return 'en'
  })

  const languageName = languageNamesByLocale[locale]

  const changeLocale = useCallback(
    (requestedRegion: ShopRegion, requestedLocale?: string) => {
      const priorLanguageAndRegionParam = `/${languageGroup}-${region.id}`
      const newRegion = requestedRegion
      const newLocale = requestedLocale ?? requestedRegion.defaultLocale

      setCookie(cookieKeys.region.key, newRegion.id, cookieKeys.region.options)
      setCookie(cookieKeys.locale.key, newLocale, cookieKeys.locale.options)

      // Our locales within our app context are true locales. That is also how Contentful content
      // is broken up. But our URLs are a language plus a country. Which is not a true locale.
      // For example: en-EU, aka European English is a locale but not in our routing method.
      // So we get the languageGroup, then use that with the region code to reload the page.
      const newLanguageGroup = retrieveLanguageGroupsForRegion(newRegion, {
        locale: newLocale,
      })
      const newLanguageAndRegionParam = `/${newLanguageGroup}-${newRegion.id}`

      // Strip out existing prefix, add new prefix.
      const destination = new URL(window.location.href)
      destination.pathname = `${newLanguageAndRegionParam}${destination.pathname.replace(
        priorLanguageAndRegionParam,
        ''
      )}`
      window.location.replace(destination.toString())
    },
    [languageGroup, region.id, setCookie]
  )

  return {
    isUSRegion: region.id === 'US',
    languageGroup,
    languageName,
    locale,
    locales: supportedLocaleIdentifiers,
    region,
    regions,
    changeLocale,
    requestIncludedLocalizationPathParam: initialState?.requestIncludedLocalizationPathParam,
  }
}

export const LocalizationContainer = createContainer(useLocalization)
