import styled from 'styled-components'

import { TypeStyle } from '@syconium/little-miss-figgy'

export const SizeChartButton = styled.button`
  ${TypeStyle.css.headlineMicro};
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  text-decoration: underline;

  &:hover {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`

export { SoldOut } from '../styles'
