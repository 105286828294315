import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const SwatchCheckmark: React.FC<IProps> = ({
  height = '18px',
  fill = 'white',
  width = '18px',
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={`-5 -7 20 20`}>
      <path
        d='M0.375713 3.18538L1.22425 2.33687L3.84263 4.95531L8.77571 0.0222307L9.62424 0.870759L3.84262 6.65238L0.375713 3.18538Z'
        fill={fill}
      />
    </BaseIcon>
  )
}

export const SwatchX: React.FC<IProps> = ({ height = '18px', fill = 'white', width = '18px' }) => {
  return (
    <BaseIcon height={height} width={width} viewBox={`-4 -4 18 18`}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.29282 9.70712L0.292818 1.70712L1.70703 0.292908L9.70703 8.29291L8.29282 9.70712Z'
        fill={fill}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.70718 1.70712L1.70718 9.70712L0.292969 8.29291L8.29297 0.292907L9.70718 1.70712Z'
        fill={fill}
      />
    </BaseIcon>
  )
}
