import {
  UseViewportBreakpointsOpts,
  ViewportSizes,
  useViewportBreakpoints,
} from './useViewportBreakpoints'

export const useIsViewport = (
  oneOrMultipleViewports: ViewportSizes | ViewportSizes[],
  opts: UseViewportBreakpointsOpts = { ssr: true }
) => {
  const viewport = useViewportBreakpoints(opts)

  return Array.isArray(oneOrMultipleViewports)
    ? oneOrMultipleViewports.includes(viewport)
    : [oneOrMultipleViewports].includes(viewport)
}
