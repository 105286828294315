import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const GET_NEW_TOKENS = gql(`
  mutation getNewTokens($refreshToken: String!) {
    customerTokenRefresh(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`)
