'use client'

import NextLink, { type LinkProps as NextLinkProps } from 'next/link'
import { type AnchorHTMLAttributes, type ReactNode, forwardRef } from 'react'

type LinkProps = NextLinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> & {
    children?: ReactNode
  }

const LinkNoPrefetch = forwardRef<HTMLAnchorElement, LinkProps>(function LinkWithRef(
  {
    // Turn next/link prefetching off by default.
    // @see https://github.com/vercel/next.js/discussions/24009
    prefetch = false,
    ...rest
  },
  ref
) {
  return <NextLink prefetch={prefetch} {...rest} ref={ref} />
})

export { LinkNoPrefetch as NextLink }
