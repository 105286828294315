import styled from 'styled-components'

import { TypeStyle, untilMd } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  background-color: ${o => o.theme.color.deprecated.stone};
  padding: 20px;
  text-align: center;

  @supports (display: flex) {
    display: none;
  }

  _:-ms-fullscreen,
  :root {
    display: none;
  }

  :-o-prefocus {
    display: none;
  }
`

export const Header = styled.div`
  ${TypeStyle.css.headlineSmall}
  margin-bottom: 15px;

  ${untilMd} {
    ${TypeStyle.css.headlineExtraSmall}
  }
`

export const StyledLink = styled.a`
  ${TypeStyle.css.headlineExtraExtraSmall}
  padding: 10px;
  text-decoration: underline;
`
export const LinkContainer = styled.div`
  padding-top: 20px;
`

export const Text = styled.div`
  ${TypeStyle.css.headlineExtraSmall}

  ${untilMd} {
    ${TypeStyle.css.headlineMicro}
  }
`
