import styled, { css } from 'styled-components'

import {
  ColorSelector as LMFColorSelector,
  FitSelector as LMFFitSelector,
  SizeSelector as LMFSizeSelector,
  TypeStyle,
} from '@syconium/little-miss-figgy'
import { MultiColorsSection } from '@syconium/magnolia/src/components/pages/products/ProductPage/DetailsSection/MultiColorSection/MultiColorSection'

/**
 * Though this styled component contains no styles of its own, it is used as a
 * target by a parent style to apply context-specific style(s):
 */
export const ColorSection = styled.section<{
  showColorSelector?: boolean
}>`
  display: ${o => (o.showColorSelector ? 'block' : 'none')};
`

export const ColorSectionText = styled.div`
  display: flex;
`

export const FitSection = styled.section`
  margin-top: 24px;
`

export const SoldOutMessage = styled.span`
  color: ${o => o.theme.color.deprecated.red.error};
`

const selectorStyles = css`
  margin-top: 8px;
`

export const SingleColorSectionSelector = styled(LMFColorSelector)`
  ${selectorStyles}
`

export const ColorSelector = styled(MultiColorsSection)`
  ${selectorStyles}
` as typeof MultiColorsSection

export const SizeSectionTopText = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SizeChartButton = styled.span`
  ${TypeStyle.css.headlineMicro};
  font-weight: 600;
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  outline: none;
  text-decoration: underline;
`

export const SizeSelector = styled(LMFSizeSelector)`
  ${selectorStyles}
`

export const FitSelector = styled(LMFFitSelector)`
  ${selectorStyles}
`

export const SizeSection = styled.section`
  margin-top: 24px;
`
