import React from 'react'

import { Search as SearchIcon, ViewportSizes, useIsViewport } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { useSearchOverlayContext } from '@syconium/magnolia/src/containers/SearchOverlayContext'
import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { SearchContainer, SearchText } from './SearchBar.styles'

type SearchProps = {
  isUnfurled?: boolean
  languageGroup?: string
}
const Search: React.FC<SearchProps> = ({ isUnfurled = false, languageGroup }) => {
  const {
    search: { inputPlaceholder },
  } = useFixturesContext()
  if (languageGroup !== 'en') return null
  if (isUnfurled) {
    return (
      <SearchContainer>
        <SearchIcon />
        <SearchText>{inputPlaceholder}</SearchText>
      </SearchContainer>
    )
  }

  return <SearchIcon />
}

export function useSearchTab(): NavTab {
  const { shouldDisplaySearchOverlay, toggleSearchOverlay } = useSearchOverlayContext()
  const {
    search: { searchButtonAriaLabelClose, searchButtonAriaLabelOpen },
  } = useFixturesContext()
  const { languageGroup } = LocalizationContainer.useContainer()
  const isMediumOrLargeViewport = useIsViewport([ViewportSizes.medium, ViewportSizes.large])

  const searchTab: NavTab = {
    ariaLabel: shouldDisplaySearchOverlay ? searchButtonAriaLabelClose : searchButtonAriaLabelOpen,
    href: null,
    id: 'search',
    onClickish: () => {
      if (shouldDisplaySearchOverlay) {
        if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
      }
      toggleSearchOverlay()
    },
    render: () =>
      !shouldDisplaySearchOverlay && (
        <Search isUnfurled={isMediumOrLargeViewport} languageGroup={languageGroup} />
      ),
    sections: [],
    text: '',
    textColor: null,
    wrapperElement: 'button',
  }

  return searchTab
}
