import styled from 'styled-components'

import {
  fromLg,
  fromMd,
  mdStartPixels,
  pageLayoutMaxWidthPixels,
  untilMd,
} from '@syconium/little-miss-figgy'
import { sectionSideGapDesktop } from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

import { Arrow } from '../SwiperImageSlider/styles'
import { LeftArrow, RightArrow } from '../SwiperImageSlider/styles'

const rowGapDesktop = 50
const rowGapMobile = 40
export const tileGapDefault = 16

interface TileGap {
  tileGapDesktop?: number
  tileGapMobile?: number
  hasHorizontalMargin?: boolean
  hasVerticalMargin?: boolean
}

export const GridWrapper = styled.div`
  width: 100%;
`

// Grid is wider than design grid by one gutter width so we can divide by 12
// with each 12th having its own gutter. (Design grid has 11 gutters.)
export const Grid = styled.div<{ fullBleed: boolean; hasHorizontalMargin?: boolean }>`
  overflow: hidden;
  padding: 0 ${o => o.hasHorizontalMargin && o.theme.dimensions.layout.sidePadding.md + 'px'};
  ${fromMd} {
    padding: ${o => !o.fullBleed && o.hasHorizontalMargin && `0 ${sectionSideGapDesktop}px`};
  }
`

/**
 * @see https://www.rawkblog.com/2019/01/flexbox-gutters-negative-margins-solved/
 */
export const GridFlex = styled.div<TileGap>`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${o => (o.hasHorizontalMargin ? 'center' : 'flex-start')};
  margin: 0 -${o => (o.tileGapMobile ?? tileGapDefault) / 2}px -${o =>
      o.hasVerticalMargin ? rowGapMobile : 0}px;

  ${fromMd} {
    margin: 0 -${o => (o.tileGapDesktop ?? tileGapDefault) / 2}px -${o =>
        o.hasVerticalMargin ? rowGapDesktop : 0}px;
  }
`

export const GridTile = styled.div<
  TileGap & {
    visibleTilesDesktop: number
    visibleTilesMobile: number
    hasTileGaps?: boolean
    sameHeightGridTiles?: boolean
  }
>`
  ${o => o.sameHeightGridTiles && `align-self: stretch`};
  box-sizing: border-box;
  padding: 0 ${o => (o.tileGapMobile ?? tileGapDefault) / 2}px
    ${o => (o.hasTileGaps && o.hasVerticalMargin ? rowGapMobile : 0)}px;
  width: calc(1 / ${o => o.visibleTilesMobile} * 100%);

  ${fromMd} {
    padding: 0 ${o => (o.tileGapDesktop ?? tileGapDefault) / 2}px
      ${o => (o.hasTileGaps && o.hasVerticalMargin ? rowGapMobile : 0)}px;
    width: ${o =>
      o.visibleTilesDesktop === 2
        ? 'calc(5 / 12 * 100%)'
        : `calc(1 / ${o.visibleTilesDesktop} * 100%)`};
  }
`

/**
 * For variant=slider, UNRELATED to pure-react-carousel Slider
 *
 * @see https://www.rawkblog.com/2019/01/flexbox-gutters-negative-margins-solved/
 */
export const SliderWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
`

export const SliderButtonPrevious = styled(LeftArrow)<{ lifted: boolean }>`
  top: ${props => (props.lifted ? 'calc(50% - 24px)' : '50%')};
`

export const SliderButtonNext = styled(RightArrow)<{ lifted: boolean }>`
  top: ${props => (props.lifted ? 'calc(50% - 24px)' : '50%')};
`

export const Slider = styled.div<{ hasAsset?: boolean; hasVerticalMargin?: boolean }>`
  outline: none;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: ${o => (o.hasAsset ? 'flex' : 'inline-block')};
  ${untilMd} {
    flex-direction: column;
    gap: 20px;
  }
  max-width: 100%;
  ${fromMd} {
    padding-bottom: ${o => o.hasVerticalMargin && o.theme.spacing(4)};
  }

  ${Arrow} {
    opacity: 1;
  }
`

// For variant=slider, UNRELATED to pure-react-carousel Slider
export const SliderRow = styled.div<
  TileGap & { totalSlides: number; hideSectionSideGaps?: boolean; hasAsset?: boolean }
>`
  align-items: top;
  display: inline-flex;
  flex-wrap: nowrap;
  flex: 0;
  &::after,
  &::before {
    content: '';
    display: ${props =>
      props.hideSectionSideGaps || props.hasHorizontalMargin === false ? 'none' : 'block'};
    flex-grow: 0;
    flex-shrink: 0;
    width: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  }

  ${fromMd} {
    &::after {
      width: ${sectionSideGapDesktop}px;
    }
    &::before {
      width: ${props => (props.hasAsset ? tileGapDefault : sectionSideGapDesktop)}px;
    }
  }

  ${SliderButtonPrevious} {
    left: ${o =>
      o.hideSectionSideGaps ? o.theme.spacing(2) : o.theme.dimensions.layout.sidePadding.md + 'px'};
    ${fromMd} {
      left: ${o => (o.hideSectionSideGaps ? o.theme.spacing(4) : sectionSideGapDesktop + 'px')};
    }
  }

  ${SliderButtonNext} {
    right: ${o =>
      o.hideSectionSideGaps ? o.theme.spacing(2) : o.theme.dimensions.layout.sidePadding.md + 'px'};
    ${fromMd} {
      right: ${props =>
        props.hideSectionSideGaps ? props.theme.spacing(4) : sectionSideGapDesktop + 'px'};
    }
  }
`

// For variant=slider, UNRELATED to pure-react-carousel Slider
export const SliderTile = styled.div<
  TileGap & {
    visibleTilesDesktop: number
    visibleTilesMobile: number
    hideSectionSideGaps?: boolean
    minTileWidth?: number
    maxTileWidth?: number
  }
>`
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;

  padding-left: ${o => (o.tileGapMobile ?? tileGapDefault) / 2}px;
  padding-right: ${o => (o.tileGapMobile ?? tileGapDefault) / 2}px;

  &:first-of-type {
    margin-left: -${o => (o.tileGapMobile ?? tileGapDefault) / 2}px;
  }
  &:last-of-type {
    margin-right: -${o => (o.tileGapMobile ?? tileGapDefault) / 2}px;
  }

  ${fromMd} {
    padding-right: ${o => (o.tileGapDesktop ?? tileGapDefault) / 2}px;
    padding-left: ${o => (o.tileGapDesktop ?? tileGapDefault) / 2}px;
    &:first-of-type {
      margin-left: -${o => (o.tileGapDesktop ?? tileGapDefault) / 2}px;
    }
    &:last-of-type {
      margin-right: -${o => (o.tileGapDesktop ?? tileGapDefault) / 2}px;
    }
  }

  width: calc(
    ${o => 100 / (o.visibleTilesMobile + 0.2)}vw -
      ${o =>
        o.hideSectionSideGaps
          ? 0
          : (o.theme.dimensions.layout.sidePadding.md * 2) / o.visibleTilesMobile}px
  );
  min-width: calc(
    ${o => o.minTileWidth ?? 350 / (o.visibleTilesMobile + 0.2)}px -
      ${o =>
        o.hideSectionSideGaps || o.minTileWidth
          ? 0
          : (o.theme.dimensions.layout.sidePadding.md * 2) / o.visibleTilesMobile}px
  );
  max-width: calc(
    ${o => o.maxTileWidth ?? mdStartPixels / (o.visibleTilesMobile + 0.2)}px -
      ${o =>
        o.hideSectionSideGaps || o.maxTileWidth
          ? 0
          : (o.theme.dimensions.layout.sidePadding.md * 2) / o.visibleTilesMobile}px
  );

  ${fromMd} {
    width: calc(
      ${o => 100 / (o.visibleTilesDesktop + 0.2)}vw -
        ${o => (o.hideSectionSideGaps ? 0 : (sectionSideGapDesktop * 2) / o.visibleTilesDesktop)}px
    );
    min-width: min(
      ${o => o.maxTileWidth ?? 9999}px,
      calc(
        ${o => mdStartPixels / (o.visibleTilesDesktop + 0.2)}px -
          ${o =>
            o.hideSectionSideGaps
              ? 0
              : (o.theme.dimensions.layout.sidePadding.md * 2) / o.visibleTilesDesktop}px
      )
    );
    max-width: calc(
      ${o => o.maxTileWidth ?? pageLayoutMaxWidthPixels / (o.visibleTilesDesktop + 0.2)}px -
        ${o =>
          o.hideSectionSideGaps || o.maxTileWidth
            ? 0
            : (sectionSideGapDesktop * 2) / o.visibleTilesDesktop}px
    );
  }
`

export const FeaturedAsset = styled.div`
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  ${fromMd} {
    padding-left: ${sectionSideGapDesktop}px;
    width: 712px;
    width: clamp(40%, 412px, 55%);
  }

  ${fromLg} {
    width: clamp(40%, 712px, 55%);
  }

  img,
  video {
    max-height: 75vh;
    object-fit: cover;
    width: 100%;
  }
`
