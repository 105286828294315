import { useMedia } from 'react-use'

import { lgStartMedia, mdMedia } from '../../constants'

export enum ViewportSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export type UseViewportBreakpointsOpts = {
  /**
   * If true, this hook will return a stable value (`ViewportSizes.small`) on the server and on initial client render.
   * Set to `false` if using in a CSR environment that doesn't need to worry about hydration mismatches.
   */
  ssr?: boolean
}

export function useViewportBreakpoints({
  ssr = true,
}: UseViewportBreakpointsOpts = {}): ViewportSizes {
  const isLarge = useMedia(lgStartMedia, ssr ? false : undefined)
  const isMedium = useMedia(mdMedia, ssr ? false : undefined)

  return (
    (isLarge && ViewportSizes.large) || (isMedium && ViewportSizes.medium) || ViewportSizes.small
  )
}
