import { ChangeEvent, FC, HTMLInputTypeAttribute } from 'react'
import styled from 'styled-components'

import { SlideToggle } from '@syconium/little-miss-figgy'
import { Skeleton } from '@syconium/little-miss-figgy/dist/components/ProgressiveAsset/styles'

import { useFixturesContext } from '../../../../brunswick/containers/fixtures'

import {
  ErrorText,
  GenderLabelContainer,
  InputContainer,
  Label,
  SelectedGenderLabel,
  SelectorButtonContainer,
  SplitItemsContainer,
  StyledSelectorButton,
  TextInput,
  TextInputContainer,
  ToggleInputContainer,
  UnitLabel,
} from './styles'

export const MeasurementInput: FC<{
  checked: boolean
  onChange: (e: ChangeEvent) => void
}> = ({ checked, onChange }) => {
  const {
    magnolia: {
      fitQuiz: {
        formLabels: { measurements, cm, inches, slideToggleCheckmarkLabel },
      },
    },
  } = useFixturesContext()
  return (
    <InputContainer aria-label={slideToggleCheckmarkLabel}>
      <Label>{measurements}</Label>
      <SlideToggle
        labels={[{ label: inches }, { label: cm }]}
        checked={checked}
        onChange={onChange}
        uppercase={true}
      />
    </InputContainer>
  )
}

export const GenderSelect: FC<{
  id: string
  onChange: (id: string, value?: string) => void
  value?: string
  errorText?: string
}> = ({ onChange, value, id, errorText }) => {
  const {
    magnolia: {
      fitQuiz: {
        formLabels: { gender, male, female },
      },
    },
  } = useFixturesContext()
  const hasError = Boolean(errorText)
  return (
    <ToggleInputContainer>
      <GenderLabelContainer>
        <Label>{gender}</Label>
        <SelectedGenderLabel>
          {value === 'MEN' ? male : value === 'WOMEN' ? female : ''}
        </SelectedGenderLabel>
      </GenderLabelContainer>
      <SelectorButtonContainer>
        <StyledSelectorButton
          type='button'
          onClick={() => onChange(id, 'WOMEN')}
          isSelected={value === 'WOMEN'}
          aria-invalid={hasError}
        >
          {female}
        </StyledSelectorButton>
        <StyledSelectorButton
          type='button'
          onClick={() => onChange(id, 'MEN')}
          isSelected={value === 'MEN'}
          aria-invalid={hasError}
        >
          {male}
        </StyledSelectorButton>
      </SelectorButtonContainer>
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </ToggleInputContainer>
  )
}

export const TextInputField: FC<{
  value?: string
  id: string
  unit: string
  unitShorthand: string
  label?: string
  onChange: (e: ChangeEvent) => void
  className?: string
  inputType?: HTMLInputTypeAttribute
  errorText?: string
  loading: boolean
}> = ({
  value,
  id,
  onChange,
  unit,
  label,
  className,
  errorText,
  inputType,
  loading,
  unitShorthand,
}) => {
  const hasError = Boolean(errorText)
  return (
    <InputContainer className={className}>
      {label && <Label>{label}</Label>}
      <TextInputContainer>
        {loading ? (
          <Skeleton hasLoaded={!loading} />
        ) : (
          <>
            {' '}
            <TextInput
              type={inputType}
              aria-invalid={hasError}
              aria-label={unit}
              //disallow non-numeric floating point values
              //https://stackoverflow.com/questions/31706611
              onKeyDown={
                inputType === 'number'
                  ? e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  : undefined
              }
              {...{ value, id, onChange, hasError }}
            />
            <UnitLabel>{unitShorthand}</UnitLabel>
          </>
        )}
      </TextInputContainer>
      {hasError && <ErrorText>{errorText}</ErrorText>}
    </InputContainer>
  )
}
export const HalfTextInputField = styled(TextInputField)`
  width: 50%;
`
export const SplitTextInputField: FC<{
  textInputFields: {
    value?: string
    id: string
    unit: string
    onChange: (e: ChangeEvent) => void
    errorText?: string
    inputType?: HTMLInputTypeAttribute
    loading?: boolean
    unitShorthand: string
  }[]
  label: string
}> = ({ textInputFields, label }) => {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <SplitItemsContainer>
        {textInputFields.map((inputField, index) => (
          <HalfTextInputField
            {...inputField}
            loading={inputField.loading ?? false}
            key={index}
          ></HalfTextInputField>
        ))}
      </SplitItemsContainer>
    </InputContainer>
  )
}
