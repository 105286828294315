import styled from 'styled-components'

import { fromMd, untilMd } from '@syconium/little-miss-figgy'
import {
  SliderButtonNext,
  SliderButtonPrevious,
  SliderRow,
} from '@syconium/magnolia/src/brunswick/components/TileLayout/styles'

import { LayoutVariant } from '../../../../../../TileLayout'

export const Wrapper = styled.div`
  ${SliderRow}::before, ${SliderRow}::after {
    width: ${o => o.theme.dimensions.layout.sidePadding.md}px;
  }
  ${fromMd} {
    padding-top: ${o => o.theme.spacing(5)};
    padding-bottom: ${o => o.theme.spacing(5)};
  }
`

export const VariantWrapper = styled.div<{ layoutVariantDesktop: LayoutVariant }>`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  ${fromMd} {
    margin-left: -40px;
    margin-right: ${o => (o.layoutVariantDesktop === 'grid' ? '0' : '-40px')};
  }
`
export const CoreStylesVariantSlider = styled.div<{ layoutVariantDesktop: LayoutVariant }>`
  ${fromMd} {
    padding-top: ${o => o.theme.spacing(5)};
    padding-bottom: ${o => o.theme.spacing(5)};
  }
  ${untilMd} {
    padding-bottom: ${o => o.theme.spacing(6)};
  }
  overflow: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  position: relative;
  scroll-behavior: smooth;
`
export const EntryCard = styled.div<{ layoutVariantDesktop: LayoutVariant }>`
  background-color: ${o => o.theme.color.deprecated.obsidian};
  border-radius: ${o => o.theme.spacing(2)};
  width: 36vw;
  min-width: 130px;
  max-width: 300px;

  ${fromMd} {
    ${o =>
      o.layoutVariantDesktop === 'grid'
        ? `width: 20%;
        min-width: unset;
        max-width: unset;`
        : `width: 20vw;
    min-width: 142px;
    max-width: 336px;`}
  }
`
export const EntryCardHeader = styled.div<{ oneWordPerLine: boolean; minWordLength: number }>`
  color: ${o => o.theme.color.deprecated.white.primary};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2.4px;
  font-weight: 700;
  padding: ${o => o.theme.spacing(4)} ${o => o.theme.spacing(4)} 0 ${o => o.theme.spacing(4)};
  overflow-x: hidden;
  hyphens: auto;
  width: ${o => o.minWordLength}em;
  ${fromMd} {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2.7px;
    padding: ${o => o.theme.spacing(6)} ${o => o.theme.spacing(6)} 0 ${o => o.theme.spacing(6)};
  }
`

export const EntryCardDescription = styled.div`
  ${fromMd} {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.65px;
    padding: ${o => o.theme.spacing(4)} ${o => o.theme.spacing(6)} 0 ${o => o.theme.spacing(6)};
  }
  color: ${o => o.theme.color.deprecated.white.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: ${o => o.theme.spacing(3)} ${o => o.theme.spacing(4)} 0 ${o => o.theme.spacing(4)};
`

export const TileWrapper = styled.fieldset`
  display: flex;
  gap: 12px;
  width: 100%;
  height: 100%;
  ${fromMd} {
    gap: 24px;
    justify-content: center;
  }
`

export const SliderButtonRight = styled(SliderButtonNext)`
  opacity: 1;
  right: 8px;
  > svg {
    margin-left: 0;
    > path {
      stroke-width: 1;
    }
  }
`

export const SliderButtonLeft = styled(SliderButtonPrevious)`
  opacity: 1;
  left: 8px;
  > svg {
    margin-left: 0;
    > path {
      stroke-width: 1;
    }
  }
`

export const Legend = styled.legend`
  height: 0;
  position: absolute;
  width: 0;
  opacity: 0;
`

export const SpacerTile = styled.div`
  width: 16px;
`
