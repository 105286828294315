import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

export const WrapperButton = styled.button`
  background: none;
  border: none;
  display: flex;
`

export const Label = styled.label`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  ${TypeStyle.css.headlineExtraExtraSmall}
  ${o => o.theme.typography.effect.uppercase}
  flex-grow: 1;
  height: 30px;
  min-width: initial;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  > input[type='radio']:checked + span {
    border-bottom: 3px solid black;
    color: ${o => o.theme.color.deprecated.obsidian};
    padding-bottom: 1px;
  }

  ${fromMd} {
    flex-grow: initial;
  }
`
export const Nav = styled(TypeStyle.HeadlineSmall)`
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
  padding-bottom: 3px;
  overflow: scroll hidden;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;
  scrollbar-width: none;

  ${fromMd} {
    justify-content: flex-start;
    gap: ${o => o.theme.spacing(6)};
  }
`

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  display: none;

  &:checked + span {
    font-weight: bold;
  }
`

export const Span = styled.span`
  display: block;
  height: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;
  padding-left: ${o => o.theme.spacing(2)};
  padding-right: ${o => o.theme.spacing(2)};
`
