import uniq from 'lodash-es/uniq'

// TODO: We should not be using a type from our design system for
// managing size data related to our GraphQL queries...
import { Size } from '@syconium/little-miss-figgy'

import { Fit } from '../../constants'
import { CartItem } from '../../types/figs'
import { ProductGroup, Variant } from '../../types/graphql'

export function deriveMapFromList<Key extends string | number | symbol, Value>(
  list: ReadonlyArray<Value>,
  extractKey: (value: Value) => Key
): Record<Key, Value>
export function deriveMapFromList<Key extends string | number | symbol, Value, T>(
  list: ReadonlyArray<Value>,
  extractKey: (value: Value) => Key,
  transformValue: (value: Value) => T
): Record<Key, T>
export function deriveMapFromList<Key extends string | number | symbol, Value, T>(
  list: ReadonlyArray<Value>,
  extractKey: (value: Value) => Key,
  transformValue?: (value: Value) => T
) {
  return list.reduce(
    (map, current) => ({
      ...map,
      [extractKey(current)]: transformValue ? transformValue(current) : current,
    }),
    {}
  )
}

export function formatPrettyTitle(variant: Variant | null | undefined): string {
  return variant?.style ?? variant?.productGroup.title ?? variant?.product.title ?? ''
}

export function formatPrettyTitlePG(productGroup: ProductGroup | null | undefined): string {
  return productGroup?.style ?? productGroup?.title ?? productGroup?.products[0]?.title ?? ''
}

export function formatPrettyColorName(name: string): string {
  return name.replace(/\s*\/\s*/g, ' / ')
}

export function deriveSizes(coloredAndFit: Variant[], productGroupSizes: string[]): Size[] {
  const fullSizesMap = ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL']

  const sizeMap =
    productGroupSizes.length >= coloredAndFit.length ? productGroupSizes : fullSizesMap

  const sortSizes = (sizes: Size[]) => {
    const sizeIndexes: { [code: string]: number } = {}
    const sortedSizes: Size[] = []

    for (let i = 0; i < sizes.length; i++) {
      const sizeCode = sizes[i]!.shorthand
      sizeIndexes[sizeCode] = i
    }

    for (let i = 0; i < sizeMap.length; i++) {
      if (sizeIndexes[sizeMap[i]!]! >= 0) {
        sortedSizes.push(sizes[sizeIndexes[sizeMap[i]!]!]!)
      }
    }

    return sortedSizes
  }

  const generatedSizes = coloredAndFit
    .filter(variant => !!variant.size)
    .map(variant => ({
      shorthand: variant.size!,
      soldOut: variant.soldOut,
    }))

  return sortSizes(generatedSizes)
}

export function isRegularFit(fitKey: string | undefined | null): boolean {
  if (fitKey === undefined || fitKey === null) return false
  return ['', Fit.REGULAR].includes(fitKey)
}

export function areFitsEquivalent(
  fitKey1: string | undefined | null,
  fitKey2: string | undefined | null
): boolean {
  return fitKey1 === fitKey2 || (isRegularFit(fitKey1) && isRegularFit(fitKey2))
}

export function deriveFits(
  currentColorKey: string,
  variants: Variant[] = [],
  productGroupFitKeys: string[] = []
): string[] {
  const variantFitKeys = uniq(
    variants.filter(variant => variant.rawColor === currentColorKey).map(variant => variant.rawFit)
  )

  return productGroupFitKeys.filter(fitKey =>
    variantFitKeys.some(variantFitKey => areFitsEquivalent(variantFitKey, fitKey))
  )
}

export function deriveFitNamesMap(productGroup: ProductGroup): ReadonlyMap<string, string> {
  const fitNames = new Map<string, string>()
  productGroup.rawFits?.forEach((fitKey, index) => {
    fitNames.set(fitKey, productGroup.fits[index] || fitKey)
  })

  return fitNames
}

export function isStandardEmbroideryItem(cartItem: CartItem): boolean {
  const isEmbroidery = checkIsEmbroidery(cartItem.productType)
  return (
    isEmbroidery &&
    cartItem.properties._guid !== undefined &&
    cartItem.properties._embroidery_version === '1.5'
  )
}

export function isStandardEmbroiderableProduct(cartItem: CartItem): boolean {
  const isEmbroidery = checkIsEmbroidery(cartItem.productType)
  return !isEmbroidery && cartItem.properties._guid !== undefined
}

export function isTeamsEmbroiderableProduct(cartItem: CartItem): boolean {
  const isEmbroidery = checkIsEmbroidery(cartItem.productType)
  return !isEmbroidery && cartItem.properties._groupid !== undefined
}

export function isTeamsEmbroideryItem(cartItem: CartItem): boolean {
  const isEmbroidery = checkIsEmbroidery(cartItem.productType)
  return isEmbroidery && cartItem.properties._groupId !== undefined
}

export function isLogoEmbroiderySetup(cartItem: CartItem): boolean {
  return cartItem.properties._logo_setup_fee ? true : false
}

export function addOrRemoveItemFromList<T extends string | number>({
  item,
  list,
  operation,
}: {
  item: T
  list: ReadonlyArray<T>
  operation: 'ADD' | 'REMOVE'
}): T[] {
  if (operation === 'REMOVE' && list.includes(item)) {
    return list.filter(element => element !== item)
  } else if (operation === 'ADD' && !list.includes(item)) {
    return [...list, item]
  } else {
    return [...list]
  }
}

export function toggleItemInList<T extends string | number>(item: T, list: ReadonlyArray<T>): T[] {
  if (list.includes(item)) return list.filter(element => element !== item)
  return [...list, item]
}

export function isRecordEmpty<K extends string | number | symbol, V>(
  record: Record<K, V>
): boolean {
  return Object.keys(record).length === 0
}

export function isBrowser(): boolean {
  return typeof window !== 'undefined' && typeof document !== 'undefined'
}

export function isServer(): boolean {
  return !isBrowser()
}

export const getQueryParamArray: (
  value: Array<string> | string | null | undefined
) => Array<string> = value => {
  return Array.isArray(value) ? value : (value as string)?.split(',').filter(Boolean) || []
}

const checkIsEmbroidery = (productType: string) => {
  const x = productType?.toLowerCase()

  if (x === 'embroidery' || x === 'stickerei' || x === 'bordado' || x === 'broderie') return true
  else return false
}
