'use client'

import { createContext, useContext } from 'react'

import { PageSection } from '../../../../__generated__/graphql/catalog/graphql'

import { getContentfulPageSectionAnalyticsName } from './ContentfulPageSectionProvider.server'

type ContentfulPageSectionContextInput = {
  pageSectionIndex: number
  pageSectionTypename: NonNullable<PageSection['__typename']>
}

type ContentfulPageSectionContextOutput = {
  pageSectionIndex: number
  pageSectionAnalyticsName: string
}

const ContentfulPageSectionContext = createContext<ContentfulPageSectionContextInput | undefined>(
  undefined
)

type ContentfulPageSectionProviderProps = ContentfulPageSectionContextInput & {
  children?: React.ReactNode
}

export const ContentfulPageSectionProvider = ({
  children,
  ...value
}: ContentfulPageSectionProviderProps) => {
  return (
    <ContentfulPageSectionContext.Provider value={value}>
      {children}
    </ContentfulPageSectionContext.Provider>
  )
}

export const useContentfulPageSectionContext = (): ContentfulPageSectionContextOutput => {
  const pageSectionContext = useContext(ContentfulPageSectionContext)
  return {
    pageSectionIndex: pageSectionContext?.pageSectionIndex ?? -1,
    pageSectionAnalyticsName: pageSectionContext?.pageSectionIndex
      ? getContentfulPageSectionAnalyticsName(pageSectionContext.pageSectionTypename)
      : 'section-not-named',
  }
}
