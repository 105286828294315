import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_KIT = gql(`
  query fetchKit($handle: String!, $isPreview: Boolean = false) {
    kit(handle: $handle, preview: $isPreview) {
      handle
      testimonialsTitle
      testimonialsSubtitle
      testimonials {
        personName
        quote
        occupation
        instagram
        image
      }
      sections {
        __typename
        ... on RawHtmlSection {
          ...RawHtmlSectionFragment
        }
        ...PageBannerSectionFragment
        ...PageHeroSectionFragment
        ...PageTileSectionFragment
      }
      accordion {
        ...AccordionViewFragment
      }
      groupItemsByColor
      defaultColors
      hideProductDetails
    }
  }
`)
