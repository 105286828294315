import Link from 'next/link'
import * as React from 'react'
import { useSpring } from 'react-spring'
import { useMeasure } from 'react-use'

import { Animate } from '@syconium/little-miss-figgy'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { LocalizationContainer } from '@syconium/magnolia/src/brunswick/containers/localization/LocalizationContainer'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { useGoogleTagManager } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'
import { intlPrettyCurrency } from '@syconium/magnolia/src/lib/utils'

import { IProps } from './IProps'
import { ProductName } from './ProductName'
import {
  AutoCompleteResults,
  CompareAtPrice,
  Container,
  EffectivePrice,
  ErrorContainer,
  ErrorMessage,
  PopularSearchItem,
  PopularSearchResults,
  PopularSearchTitle,
  Prices,
  ProductDetails,
  ProductTile,
  StyledResponsiveImage,
  imageSize,
} from './styles'

function noOp(): void {}

export const SearchOverlayResults: React.FC<IProps> = ({
  autoCompleteResults,
  hasError,
  onClearFormClick = noOp,
  onClickSearchOverlayProduct = noOp,
  popularSearchResults,
  popularSearchTitle,
  searchText,
}) => {
  const { search: searchCopy } = useFixturesContext()
  const { locale, region } = LocalizationContainer.useContainer()
  const { pushProductClick } = useGoogleTagManager()

  const [bind, { height }] = useMeasure<HTMLDivElement>()

  const containerSpring = useSpring({
    height: autoCompleteResults.length > 0 || popularSearchResults.length > 0 ? height : 0,
  })

  return (
    <>
      <Container style={containerSpring}>
        <div ref={bind}>
          {!hasError && (
            <>
              {autoCompleteResults.length > 0 && (
                <AutoCompleteResults aria-label='Search results'>
                  {autoCompleteResults.map((result, index) => {
                    const shopifyId = result.defaultVariant.shopifyId.split('/').pop() ?? ''
                    const discountPrice = result.defaultVariant.priceDetails?.discountPrice?.amount
                    const price = result.defaultVariant.priceDetails.price.amount
                    const effectivePrice =
                      discountPrice ?? result.defaultVariant.priceDetails.price.amount
                    const compareAtPrice =
                      !!discountPrice && discountPrice !== price ? price : undefined
                    const currency = result.defaultVariant.priceDetails.price.currency

                    return (
                      <ProductTile
                        role='listitem'
                        key={result.defaultVariant?.sku}
                        {...trackEvent({
                          category: 'search',
                          action: 'click related search query',
                          label: result.url,
                        })}
                      >
                        <Link
                          onClick={() => {
                            pushProductClick({
                              variant: {
                                category: result.category,
                                currencyType: currency,
                                externalParentId: '',
                                discountPrice: discountPrice ?? null,
                                handle: result.handle,
                                price: effectivePrice,
                                sku: result.defaultVariant?.sku,
                                shopifyId,
                              },
                              list: 'instant-search-results',
                              position: index,
                            })
                            onClickSearchOverlayProduct({ query: searchText, id: shopifyId })
                          }}
                          href={result.url}
                        >
                          {result.images?.nodes?.[0]?.source && (
                            <StyledResponsiveImage
                              alt={result.title}
                              src={result.images?.nodes?.[0]?.source}
                              widths={{ unit: 'px', sm: imageSize, md: imageSize }}
                              aspectRatios={{ sm: 1, md: 1 }}
                              loading='default'
                            />
                          )}
                          <ProductDetails>
                            <ProductName allText={result.title} targetText={searchText} />
                            {
                              <Prices>
                                {effectivePrice && (
                                  <Animate.FadeIn>
                                    <EffectivePrice
                                      className='money'
                                      isDiscounted={!!discountPrice}
                                    >
                                      {intlPrettyCurrency({
                                        cents: effectivePrice,
                                        region: region,
                                        locale: locale,
                                      })}
                                    </EffectivePrice>
                                  </Animate.FadeIn>
                                )}
                                {compareAtPrice && (
                                  <Animate.FadeIn>
                                    <CompareAtPrice className='money'>
                                      {intlPrettyCurrency({
                                        cents: compareAtPrice,
                                        region: region,
                                        locale: locale,
                                      })}
                                    </CompareAtPrice>
                                  </Animate.FadeIn>
                                )}
                              </Prices>
                            }
                          </ProductDetails>
                        </Link>
                      </ProductTile>
                    )
                  })}
                </AutoCompleteResults>
              )}

              {popularSearchResults.length > 0 && (
                <PopularSearchResults>
                  <PopularSearchTitle>{popularSearchTitle}</PopularSearchTitle>
                  <ul>
                    {popularSearchResults.map((search, index) => (
                      <PopularSearchItem key={`${search}-${index}`}>
                        <Link
                          href={`/search?q=${search}`}
                          onClick={onClearFormClick}
                          {...trackEvent({
                            category: 'search 2.0',
                            action: 'click',
                            label: 'popular item',
                          })}
                        >
                          {search}
                        </Link>
                      </PopularSearchItem>
                    ))}
                  </ul>
                </PopularSearchResults>
              )}
            </>
          )}
        </div>
      </Container>
      {hasError && (
        <ErrorContainer>
          <ErrorMessage>{searchCopy.errorMessage}</ErrorMessage>
        </ErrorContainer>
      )}
    </>
  )
}
