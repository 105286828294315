import { PageTestimonialSection, Testimonial } from '../../types/graphql'
import { VideoSectionProps } from '../components/page-sections/VideoSection'

export const sectionTitleImage =
  'https://cdn.shopify.com/s/files/1/0139/8942/files/titleImage-demo.png?v=1596066807'

export const videoSection: VideoSectionProps = {
  __typename: 'VideoSection',
  id: 'some-contentful-id',
  titleImageDesktop: sectionTitleImage,
  titleImageMobile: sectionTitleImage,
  title: 'Lose 50 pounds in 2 weeks',
  subtitle: 'It’s so easy',
  linkText1: 'Watch the Video',
  linkHref1: 'https://giphy.com/search/pills',
  linkText2: 'Click Here Now',
  linkHref2: 'https://giphy.com/search/snake-oil',
  textColor: '#000',
  buttonVariant: 'black-on-white',
  imageDesktop:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/star-wars-lp-hero-desktop.jpg?v=1574105073',
  imageMobile:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/star-wars-lp-hero-mobile.jpg?v=1574105072',
  inlineVideoDesktop:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_3600x1800_handbrake.mp4?v=159130760',
  inlineVideoMobile:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/lsq2_boulder_homepage_mobile_828x1012_handbrake.mp4?v=1591307602',
  inlineVimeoIdDesktop: 586948724,
  inlineVimeoIdMobile: 586948761,
  modalVideoPlatform: 'youtube',
  modalVideoId: 'hNlpzqx3_Oo',
  textContentMaxWidthDesktop: null,
  textContentMaxWidthMobile: null,
  isCriticalVideo: false,
  name: 'A name',
}

export const testimonial: Testimonial = {
  image: 'https://cdn.shopify.com/s/files/1/0139/8942/files/Awesome-Humans-LP-Gallery-Ana.jpg',
  quote: '“Love this top, the material is so soft with some stretch that allows movement”',
  personName: 'Ana W.',
  occupation: 'Registered Nurse',
  instagram: 'handle',
}

export const pageTestimonialSection: PageTestimonialSection = {
  __typename: 'PageTestimonialSection',
  id: 'some-contentful-id',
  title: 'Awesome Humans',
  subtitle: 'Why wear scrubs when you can wear FIGS',
  testimonials: [],
}
