import * as React from 'react'

import { intlPrettyCurrency } from '@syconium/magnolia/src/lib/utils'

import { useFixturesContext } from '../../containers/fixtures'
import { LocalizationContainer } from '../../containers/localization/LocalizationContainer'

import { IProps } from './IProps'
import { CrossedOutPrice, DiscountPrice, Price, ValuePrice } from './styles'

export const PriceDisplay: React.FC<IProps> = ({
  discountPrice,
  displayDiscountAsValue,
  discountPriceExplicitFmt,
  price,
  priceExplicitFmt,
  priceRange,
  pgDiscountPriceRange,
}) => {
  const { locale, region } = LocalizationContainer.useContainer()
  const { valueSignalLabel } = useFixturesContext().collections.productGroupTile
  const formattedPrice =
    priceExplicitFmt ?? intlPrettyCurrency({ cents: price, region: region, locale: locale })

  if (pgDiscountPriceRange && pgDiscountPriceRange.min !== pgDiscountPriceRange.max) {
    if (pgDiscountPriceRange.max === price) {
      return (
        <>
          <DiscountPrice>
            {intlPrettyCurrency({
              cents: pgDiscountPriceRange.min,
              region: region,
              locale: locale,
            })}
          </DiscountPrice>{' '}
          <Price>
            {'- '} {formattedPrice}
          </Price>
        </>
      )
    }
    return (
      <>
        <DiscountPrice>
          {intlPrettyCurrency({
            cents: pgDiscountPriceRange.min,
            region: region,
            locale: locale,
          })}
          {' - '}
          {intlPrettyCurrency({
            cents: pgDiscountPriceRange.max,
            region: region,
            locale: locale,
            explicitFormat: true,
          })}
        </DiscountPrice>{' '}
        <CrossedOutPrice>{formattedPrice}</CrossedOutPrice>
      </>
    )
  } else if (priceRange) {
    return (
      <>
        <Price>
          {intlPrettyCurrency({ cents: priceRange.min, region: region, locale: locale })}
        </Price>
        -{' '}
        <Price>
          {intlPrettyCurrency({ cents: priceRange.max, region: region, locale: locale })}
        </Price>
      </>
    )
  }

  if (discountPrice && (!price || (!!price && discountPrice < price))) {
    return (
      <>
        <DiscountPrice displayDiscountAsValue={displayDiscountAsValue}>
          {discountPriceExplicitFmt ??
            intlPrettyCurrency({ cents: discountPrice, region: region, locale: locale })}
        </DiscountPrice>{' '}
        {!!price &&
          (displayDiscountAsValue ? (
            <ValuePrice>
              ({formattedPrice} {valueSignalLabel})
            </ValuePrice>
          ) : (
            <CrossedOutPrice>{formattedPrice}</CrossedOutPrice>
          ))}
      </>
    )
  }

  if (!price) return null

  return <Price>{formattedPrice}</Price>
}
