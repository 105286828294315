import { FC } from 'react'

import { isStandardEmbroideryItem } from '@syconium/magnolia/src/lib/utils'

import { CartItem } from '../../../types/figs'
import { CartContainer } from '../../containers/cart'
import { useFixturesContext } from '../../containers/fixtures'
import { PortalConfigContainer } from '../../containers/PortalConfigContainer'
import { EmbroideryCartItem, RegularCartItem } from '../Cart'

export const MiniCartItem: FC<{
  item: CartItem
  index: number
  toggleIsRevealed: () => void
  /**
   * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
   * @deprecated - do not use outside of temporary Product Bundle Discounts work.
   * @todo remove this after we remove this feature.
   */
  showDiscountAtCheckoutCopy: boolean
}> = ({ item, index, toggleIsRevealed, showDiscountAtCheckoutCopy }) => {
  const {
    cart,
    decrementItemQuantity,
    incrementItemQuantity,
    setItemQuantity,
    removeItem,
    updateInProgress,
  } = CartContainer.useContainer()
  const { cart: miniCartCopy } = useFixturesContext()
  const { hasMandatoryTextEmbroidery, hasMandatoryLogoEmbroidery } =
    PortalConfigContainer.useContainer()

  const key: string = `${item.key}-${index}`

  if (isStandardEmbroideryItem(item)) {
    // Get the item that is associated with this embroidery item
    const [_, associatedEmbroiderableProduct] =
      Object.entries(cart.items).find(
        ([_, cartItem]) =>
          item.key !== cartItem.key && item.properties._guid === cartItem.properties._guid
      ) ?? []

    // If the associated embroiderable products should be removed along with the embroidery
    const shouldRemoveRelatedPortalProduct =
      hasMandatoryLogoEmbroidery(associatedEmbroiderableProduct?.productType) ||
      hasMandatoryTextEmbroidery(associatedEmbroiderableProduct?.productType)

    return (
      <EmbroideryCartItem
        cartItemProperties={item.properties}
        currency={cart.currencyCode}
        eventCategory='minicart'
        key={key}
        label={miniCartCopy.embroideryItemLabel}
        effectivePrice={item.effectivePrice}
        fullPrice={item.fullPrice}
        removeItem={() => removeItem(item.key, shouldRemoveRelatedPortalProduct)}
        title={item.title}
      />
    )
  } else {
    return (
      <RegularCartItem
        cartCurrency={cart.currencyCode}
        isEmbroiderable={item.productGroup?.embroiderable && !!item.product?.embroidery?.length}
        hasEmbroidery={!!item.properties._guid}
        cartIsUpdating={updateInProgress}
        cartItem={item}
        decrementQuantity={() => decrementItemQuantity(item.key)}
        eventCategory='minicart'
        incrementQuantity={() => incrementItemQuantity(item.key)}
        key={key}
        removeItem={() => removeItem(item.key)}
        setQuantity={newQuantity => setItemQuantity({ newQuantity, key: item.key })}
        toggleMiniCartSlideOut={toggleIsRevealed}
        uniqueId={key}
        showDiscountAtCheckoutCopy={showDiscountAtCheckoutCopy}
      />
    )
  }
}
