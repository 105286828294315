import { createContainer } from 'unstated-next'

import { useFetchRecommendations } from './useFetchRecommendation'

export const useInitialSizeProfile = () => {
  const { recommendationsMen, recommendationsWomen, loading } = useFetchRecommendations()

  return { recommendations: { men: recommendationsMen, women: recommendationsWomen }, loading }
}

export const InitialSizeProfileContainer = createContainer(useInitialSizeProfile)
