import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const SEARCH_AUTOCOMPLETE = gql(`
  query SearchAutocomplete($first: Int!, $query: String!) {
    search(query: $query) {
      suggestions {
        query
      }
      items(first: $first) {
        nodes {
          product {
            id
            handle
            title
            category
            productGroup {
              handle
              rawFits
            }
            colorInfo {
              rawName
            }  
            images(first: 1) {
              nodes {
                source
              }
            }
            defaultVariant {
              shopifyId
              sku
              priceDetails {
                discountPrice {
                  amount
                }
                price {
                  amount
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`)
