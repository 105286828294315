import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const FETCH_GIFT_PACKAGE = gql(`
  query fetchGiftPackage($slug: String!) {
    giftPackage(slug: $slug) {
      id
      slug
      companyName
      companyLogo
      codePrefix
      errorMessage
      verificationCopy
      styleImageMens
      styleImageWomens
      giftPackageOptions {
        id
        title
        price
        promoImageMens
        promoImageWomens
        giftItemCollections {
          ...GiftItemCollection
        }
      }
    }
  }
`)
