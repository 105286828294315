import { createContainer } from 'unstated-next'

import { useFixturesContext } from '../../../../brunswick/containers/fixtures'

export const validRecommendationCategory = [
  'Scrub Top',
  'Scrub Pants',
  'Scrub Jacket',
  'Jumpsuits',
] as const

export type ValidRecommendationCategory = (typeof validRecommendationCategory)[number]

export type ValidFitQuizRecommendationSizeValuesType =
  | 'XXS'
  | 'XS'
  | 'S'
  | 'M'
  | 'L'
  | 'XL'
  | 'XXL'
  | '3XL'
  | '4XL'
  | '5XL'

export type ValidFitQuizRecommendationFitValuesType = 'petite' | 'tall' | 'short' | 'regular'
export type FitQuizRecommendationType = {
  [Category in ValidRecommendationCategory]?: {
    size: ValidFitQuizRecommendationSizeValuesType
    fit: ValidFitQuizRecommendationFitValuesType
  }
}

const useFitQuizStringMaps = () => {
  const {
    magnolia: {
      fitQuiz: {
        recommendations: {
          labels: { jumpsuits, jackets, pants, tops },
          sizes: { xxs, xs, s, m, l, xl, twoxl, threexl, fourxl, fivexl },
          fits: { petite, tall, regular, short },
        },
      },
    },
  } = useFixturesContext()

  const categoryLabelMap: Record<ValidRecommendationCategory, string> = {
    'Scrub Top': tops,
    'Jumpsuits': jumpsuits,
    'Scrub Pants': pants,
    'Scrub Jacket': jackets,
  }

  const sizesMap: Record<ValidFitQuizRecommendationSizeValuesType, string> = {
    'XXS': xxs,
    'XS': xs,
    'S': s,
    'M': m,
    'L': l,
    'XL': xl,
    'XXL': twoxl,
    '3XL': threexl,
    '4XL': fourxl,
    '5XL': fivexl,
  }

  const fitsMap: Record<ValidFitQuizRecommendationFitValuesType, string> = {
    petite: petite,
    regular: regular,
    short: short,
    tall: tall,
  }
  function isValidCategory(
    categoryCandidate?: string
  ): categoryCandidate is keyof typeof categoryLabelMap {
    return typeof categoryCandidate === 'string' && categoryCandidate in categoryLabelMap
  }

  function isValidFit(fitCandidate?: string | null): fitCandidate is keyof typeof fitsMap {
    return typeof fitCandidate === 'string' && fitCandidate in fitsMap
  }

  function isValidSize(sizeCandidate?: string | null): sizeCandidate is keyof typeof sizesMap {
    return typeof sizeCandidate === 'string' && sizeCandidate in sizesMap
  }

  return {
    fitsMap,
    sizesMap,
    categoryLabelMap,
    isValidCategory,
    isValidFit,
    isValidSize,
  }
}

export const FitQuizStringMapsContainer = createContainer(useFitQuizStringMaps)
