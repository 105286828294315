import styled from 'styled-components'

import {
  HorizontalList,
  HorizontalListDivider,
  HorizontalListItem,
  fromMd,
  pageLayoutMaxWidth,
  timingDefault,
  untilMd,
} from '@syconium/little-miss-figgy'

export const Container = styled.nav`
  background: ${o => o.theme.color.deprecated.obsidian};
  color: white;
  padding: 56px 0 120px;

  ${fromMd} {
    padding: 64px 0 52px;
  }
`

export const Promo = styled.div`
  padding: 0 24px 64px;
  text-align: center;

  ${fromMd} {
    padding: 0;
    text-align: start;
  }
`

export const PromoTagline = styled.h2`
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 7px;
  line-height: 1.09;
  text-transform: uppercase;

  ${fromMd} {
    display: table-caption;
  }
`

export const PromoHashtag = styled.h3`
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 5px;
  line-height: 2.14;
  padding-bottom: 24px;
  text-transform: uppercase;
`

export const FooterSocialLinkList = styled.ul``

export const PromoSocialItem = styled.li`
  display: inline;
  margin: 0 16px;

  &:first-child {
    margin-left: 8px;
  }

  ${fromMd} {
    margin: 0 4px;

    &:first-child {
      margin: 0 8px 0 0;
    }

    svg {
      height: 12px;
    }
  }
`

export const PromoSocialLink = styled.a`
  color: ${o => o.theme.color.deprecated.stoneDark};
  opacity: 0.5;
  transition: opacity ${timingDefault};

  &:hover {
    opacity: 1;
  }
`

export const PromoSocialLinkSRText = styled.span`
  left: -999rem;
  position: absolute;
`

export const ContactLink = styled.a``

export const FooterDarkText = styled.span`
  color: ${o => o.theme.color.deprecated.stoneDark};
`

export const MenuTitle = styled.h3`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;

  /* keep content below vertically aligned when this text wraps at narrow column width */
  line-height: 1.2;
  max-height: 1.2em;
`

export const MenuList = styled.ul`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 1.69;
  padding: 0 0 0 16px;

  ${fromMd} {
    font-size: 11px;
    line-height: 1.45;
    padding: 24px 0 0;
  }
`

export const MenuListItem = styled.li`
  padding: 12px 0 8px;

  ${fromMd} {
    padding: 5px 0;
  }

  p {
    padding: 5px 0;
  }
`

export const MenuLink = styled.a`
  ${fromMd} {
    &:hover {
      font-weight: bold;
    }
  }
`

export const MenuLinkItem = styled(MenuLink)`
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  padding: 24px;
`

export const StyledFooter = styled.footer`
  align-items: center;
  color: ${o => o.theme.color.deprecated.gray.light};
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  max-width: ${pageLayoutMaxWidth};
  padding: 56px 0 0;

  ${fromMd} {
    align-items: flex-start;
    border-top: 0.5px solid #444;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    padding: 40px 72px 0;
  }
`

export const FooterLeft = styled.div`
  color: ${o => o.theme.color.deprecated.stoneDark};
  order: 1;
  text-align: center;
  width: 100%;

  ${fromMd} {
    order: 0;
    text-align: left;
    width: auto;
  }
`

export const FooterCopyright = styled.p`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  padding: 0 0 12px;
  text-transform: uppercase;
`
export const FooterListLink = styled.a``

export const FooterLinksList = styled(HorizontalList)`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.6px;
  line-height: 1.69;

  ${fromMd} {
    font-size: 11px;
    justify-content: flex-start;
  }

  a:hover {
    color: white;
  }
`

export const FooterLinksListItem = styled(HorizontalListItem)``

export const FooterLinksDivider = styled(HorizontalListDivider)`
  opacity: 0.6;
  margin: 0 4px;
`

export const AccordionContainer = styled.div``

export const CountryWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 48px;

  ${fromMd} {
    margin-top: 0;
  }
`

export const CountryWrapperChildren = styled.div`
  ${untilMd} {
    display: none;
  }
`
