import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Upload: React.FC<IProps> = ({ height = '24px', width = '24px' }) => {
  return (
    <BaseIcon height={height} width={width}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 24 25'
        fill='none'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M11.25 16.7862L11.25 7.52574L7.02809 11.7476L5.96743 10.687L12 4.65442L18.0325 10.687L16.9719 11.7476L12.75 7.52574L12.75 16.7862H11.25Z'
          fill='white'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M21 20.5361H3V19.0361H21V20.5361Z'
          fill='white'
        />
      </svg>
    </BaseIcon>
  )
}
