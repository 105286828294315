import styled from 'styled-components'

import { Carat as LMFCarat, Popover, verticalRhythm } from '@syconium/little-miss-figgy'

export const ToggleButton = styled.button<{ active?: boolean }>`
  font-weight: 700;
  background-color: ${o => o.active && o.theme.color.deprecated.stoneLighter};
  border-radius: 3px;
  display: inline-block;
  padding: 5px;
  position: relative;
  z-index: 3;
  &:hover {
    background-color: ${o => o.theme.color.deprecated.stoneLighter};
  }
`

export const Carat = styled(LMFCarat)`
  margin-left: ${o => o.theme.spacing(1.5)};
`

export const Panel = styled(Popover.Panel)`
  padding: ${o => o.theme.spacing(4.5, 6)};
  ${o => verticalRhythm(o.theme.spacing(1))};
`
